import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";

// Styles
import "../../../styles/components/admin/admin.scss";
import "../../../styles/components/admin/view.scss";

// Actions
import { getClassById } from "../../../actions/class.action";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";

class ViewClass extends Component {
    componentDidMount() {
        let urlParams = window.location.pathname;
        let params = urlParams.split("/");

        if (params.length >= 4) {
            this.props.getClassById(params[3]).catch(res => {
                Swal.fire("Error", res.message, "error");
            });
        }
    }

    render() {
        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="view-container">
                        <div className="item">
                            <h1>Class Details</h1>
                        </div>
                        <div className="card">
                            <div className="card-top">
                                <h2>
                                    <strong>Class Details:</strong>
                                </h2>{" "}
                            </div>
                            <div className="card-bottom header">
                                <div className="col">
                                    <h3>
                                        <strong>Title:</strong>{" "}
                                        {this.props.classes.currentClass &&
                                        this.props.classes.currentClass
                                            .class_details &&
                                        this.props.classes.currentClass
                                            .class_details.title &&
                                        this.props.classes.currentClass
                                            .class_details.title !== ""
                                            ? this.props.classes.currentClass
                                                  .class_details.title
                                            : ""}
                                    </h3>
                                    <h3>
                                        <strong>Class Function:</strong>{" "}
                                        {this.props.classes.currentClass &&
                                        this.props.classes.currentClass
                                            .class_details &&
                                        this.props.classes.currentClass
                                            .class_details.function &&
                                        this.props.classes.currentClass
                                            .class_details.function !== ""
                                            ? this.props.classes.currentClass
                                                  .class_details.function
                                            : ""}
                                    </h3>
                                    <h3>
                                        <strong>Class Price:</strong>{" "}
                                        {this.props.classes.currentClass &&
                                        this.props.classes.currentClass
                                            .class_details &&
                                        this.props.classes.currentClass
                                            .class_details.price &&
                                        this.props.classes.currentClass
                                            .class_details.price !== ""
                                            ? this.props.classes.currentClass
                                                  .class_details.price
                                            : ""}
                                    </h3>
                                </div>
                                <div className="col">
                                    <h3>
                                        <strong>Size:</strong>{" "}
                                        {this.props.classes.currentClass &&
                                        this.props.classes.currentClass
                                            .class_details &&
                                        this.props.classes.currentClass
                                            .class_details.size &&
                                        this.props.classes.currentClass
                                            .class_details.size !== ""
                                            ? this.props.classes.currentClass
                                                  .class_details.size
                                            : ""}
                                    </h3>
                                    <h3>
                                        <strong>Level:</strong>{" "}
                                        {this.props.classes.currentClass &&
                                        this.props.classes.currentClass
                                            .class_details &&
                                        this.props.classes.currentClass
                                            .class_details.level &&
                                        this.props.classes.currentClass
                                            .class_details.level !== ""
                                            ? this.props.classes.currentClass
                                                  .class_details.level
                                            : ""}
                                    </h3>
                                    <h3>
                                        <strong>Created On:</strong>{" "}
                                        {this.props.classes.currentClass &&
                                        this.props.classes.currentClass
                                            .createdAt &&
                                        this.props.classes.currentClass
                                            .createdAt !== ""
                                            ? new Date(
                                                  this.props.classes.currentClass.createdAt
                                              ).toLocaleDateString()
                                            : ""}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-top">
                                <h2>
                                    <strong>Class Summary:</strong>
                                </h2>{" "}
                            </div>
                            <div className="card-bottom header">
                                <h3>
                                    <strong>Summary:</strong>{" "}
                                    {this.props.classes.currentClass &&
                                    this.props.classes.currentClass
                                        .class_details &&
                                    this.props.classes.currentClass
                                        .class_details.summary &&
                                    this.props.classes.currentClass
                                        .class_details.summary !== ""
                                        ? this.props.classes.currentClass
                                              .class_details.summary
                                        : ""}
                                </h3>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-top">
                                <h2>
                                    <strong>Class Description:</strong>
                                </h2>{" "}
                            </div>
                            <div className="card-bottom header">
                                <h3>
                                    <strong>Description:</strong>{" "}
                                    {this.props.classes.currentClass &&
                                    this.props.classes.currentClass
                                        .class_details &&
                                    this.props.classes.currentClass
                                        .class_details.description &&
                                    this.props.classes.currentClass
                                        .class_details.description !== ""
                                        ? this.props.classes.currentClass
                                              .class_details.description
                                        : ""}
                                </h3>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-top">
                                <h2>
                                    <strong>Class Image:</strong>
                                </h2>{" "}
                            </div>
                            {this.props.classes &&
                            this.props.classes.currentClass &&
                            this.props.classes.currentClass
                                .files &&
                            this.props.classes.currentClass.files
                                .files !== ""
                                ? this.props.classes.currentClass.files
                                      .slice(0)
                                      .map((file, index) => (
                                          <img
                                              alt="Attached class"
                                              src={file.url}
                                          />
                                      ))
                                : ""}
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    classes: state.classes
});

export default connect(mapStateToProps, {
    getClassById
})(ViewClass);
