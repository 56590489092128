import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";

// Styles
import "../../../styles/components/admin/admin.scss";
import "../../../styles/components/admin/view.scss";

// Actions
import { getVideoById } from "../../../actions/video.action";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";

class ViewVideo extends Component {
    componentDidMount() {
        let urlParams = window.location.pathname;
        let params = urlParams.split("/");

        if (params.length >= 4) {
            this.props.getVideoById(params[3]).catch(res => {
                Swal.fire("Error", res.message, "error");
            });
        }
    }

    render() {
        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="view-container">
                        <div className="item">
                            <h1>Video Class Details</h1>
                        </div>
                        <div className="card">
                            <div className="card-top">
                                <h2>
                                    <strong>Video Details:</strong>
                                </h2>{" "}
                            </div>
                            <div className="card-bottom header">
                                <div className="col">
                                    <h3>
                                        <strong>Title:</strong>{" "}
                                        {this.props.videos.currentVideo &&
                                        this.props.videos.currentVideo
                                            .video_details &&
                                        this.props.videos.currentVideo
                                            .video_details.title &&
                                        this.props.videos.currentVideo
                                            .video_details.title !== ""
                                            ? this.props.videos.currentVideo
                                                  .video_details.title
                                            : ""}
                                    </h3>
                                    <h3>
                                        <strong>Video Category:</strong>{" "}
                                        {this.props.videos.currentVideo &&
                                        this.props.videos.currentVideo
                                            .video_details &&
                                        this.props.videos.currentVideo
                                            .video_details.category &&
                                        this.props.videos.currentVideo
                                            .video_details.category !== ""
                                            ? this.props.videos.currentVideo
                                                  .video_details.category
                                            : ""}
                                    </h3>
                                    <h3>
                                        <strong>Video Duration:</strong>{" "}
                                        {this.props.videos.currentVideo &&
                                        this.props.videos.currentVideo
                                            .video_details &&
                                        this.props.videos.currentVideo
                                            .video_details.duration &&
                                        this.props.videos.currentVideo
                                            .video_details.duration !== ""
                                            ? this.props.videos.currentVideo
                                                  .video_details.duration
                                            : ""}
                                    </h3>
                                </div>
                                <div className="col">
                                    <h3>
                                        <strong>Url:</strong>{" "}
                                        {this.props.videos.currentVideo &&
                                        this.props.videos.currentVideo
                                            .video_details &&
                                        this.props.videos.currentVideo
                                            .video_details.url &&
                                        this.props.videos.currentVideo
                                            .video_details.url !== ""
                                            ? this.props.videos.currentVideo
                                                  .video_details.url
                                            : ""}
                                    </h3>
                                    <h3>
                                        <strong>Created On:</strong>{" "}
                                        {this.props.videos.currentVideo &&
                                        this.props.videos.currentVideo
                                            .createdAt &&
                                        this.props.videos.currentVideo
                                            .createdAt !== ""
                                            ? new Date(
                                                  this.props.videos.currentVideo.createdAt
                                              ).toLocaleDateString()
                                            : ""}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-top">
                                <h2>
                                    <strong>Class Summary:</strong>
                                </h2>{" "}
                            </div>
                            <div className="card-bottom header">
                                <h3>
                                    <strong>Summary:</strong>{" "}
                                    {this.props.videos.currentVideo &&
                                    this.props.videos.currentVideo
                                        .video_details &&
                                    this.props.videos.currentVideo.video_details
                                        .summary &&
                                    this.props.videos.currentVideo.video_details
                                        .summary !== ""
                                        ? this.props.videos.currentVideo
                                              .video_details.summary
                                        : ""}
                                </h3>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-top">
                                <h2>
                                    <strong>Video Thumbnail:</strong>
                                </h2>{" "}
                            </div>
                            {this.props.videos &&
                            this.props.videos.currentVideo &&
                            this.props.videos.currentVideo
                                .files &&
                            this.props.videos.currentVideo.files
                                .files !== ""
                                ? this.props.videos.currentVideo.files
                                      .slice(0)
                                      .map((file, index) => (
                                          <img
                                              alt="Attached video"
                                              src={file.url}
                                          />
                                      ))
                                : ""}
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    videos: state.videos
});

export default connect(mapStateToProps, {
    getVideoById
})(ViewVideo);
