import React, { Component } from "react";
import { connect } from "react-redux";
import { DgCustomForm } from "dg-forms";
import history from "../../../history";
import { Field } from "redux-form";
import { dgSelect, dgTextArea, dgUpload, dgInput, required } from "dg-forms";
import Swal from "sweetalert2";

// Styles
import "../../../styles/components/admin/admin.scss";
import "../../../styles/components/admin/view.scss";

// Actions
import { createClass } from "../../../actions/class.action";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";

class CreateClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.handleSelectedFile = this.handleSelectedFile.bind(this);
    }
    handleSelectedFile(e) {
        e.preventDefault();

        this.setState({
            selectedFile: e.target.files
        });
    }

    onSubmit(data) {
        const form = new FormData();

        if (this.state.selectedFile && this.state.selectedFile.length > 0) {
            for (
                let index = 0;
                index < this.state.selectedFile.length;
                index++
            ) {
                form.append("files", this.state.selectedFile[index]);
            }
        }

        form.append("class_details", JSON.stringify(data));
        this.props
            .createClass(form)
            .then(res => {
                Swal.fire(`Success`, res.message, "success");
                history.push("/admin/classes");
            })
            .catch(res => {
                Swal.fire(`Error`, res.message, "error");
            });
    }
    render() {
        let fields = [
            <>
                <div className="form-row">
                    <Field
                        name="class_details.title"
                        type="text"
                        component={dgInput}
                        label="Enter a title"
                        placeholder="TITLE"
                        labelClass="required"
                        validate={[required]}
                    />
                    <Field
                        name="class_details.function"
                        type="text"
                        component={dgInput}
                        placeholder="FUNCTION"
                        label="Enter a function"
                        labelClass="required"
                        validate={[required]}
                    />
                </div>
                <div className="form-row">
                    <Field
                        name="class_details.price"
                        type="text"
                        component={dgInput}
                        placeholder="PRICE"
                        labelClass="required"
                        label="Enter a price"
                        validate={[required]}
                    />
                    <Field
                        name="class_details.size"
                        type="text"
                        component={dgInput}
                        placeholder="SIZE"
                        labelClass="required"
                        label="Enter a size"
                        validate={[required]}
                    />
                </div>
                <div className="form-row">
                    <Field
                        labelClass="required"
                        label="Enter a duration"
                        name="class_details.duration"
                        component={dgSelect}
                        validate={[required]}
                    >
                        <option value="" selected>
                            Please Select a Duration
                        </option>
                        <option value="15" selected>
                            15 Minutes or Less
                        </option>
                        <option value="30" selected>
                            30 Minutes or Less
                        </option>
                        <option value="45" selected>
                            45 Minutes or Less
                        </option>
                        <option value="60" selected>
                            60 Minutes or Less
                        </option>
                        <option value="61" selected>
                            60 Minutes or More
                        </option>
                    </Field>
                    <Field
                        labelClass="required"
                        label="Enter a level"
                        name="class_details.level"
                        component={dgSelect}
                        validate={[required]}
                    >
                        <option value="" selected>
                            Please Select a Level
                        </option>
                        <option value="Beginner/Improver" selected>
                            Beginner/Improver
                        </option>
                        <option value="Intermediate" selected>
                            Intermediate
                        </option>
                        <option value="Advanced" selected>
                            Advanced
                        </option>
                    </Field>
                </div>
                <div className="form-row">
                    <Field
                        name="class_details.summary"
                        type="text"
                        component={dgTextArea}
                        placeholder="SUMMARY"
                        labelClass="required"
                        label="Enter a summary"
                        validate={[required]}
                    />
                </div>
                <Field
                    multiple={false}
                    label="Class Image"
                    labelClass="required"
                    name="files"
                    onChange={this.handleSelectedFile}
                    accept={".png, .jpg, .jpeg"}
                    component={dgUpload}
                />
                <Field
                    name="class_details.description[0]"
                    type="text"
                    component={dgTextArea}
                    placeholder={"paragraph 1"}
                    labelClass="required"
                    label="Enter a description (Required)"
                    validate={[required]}
                />
                <Field
                    name="class_details.description[1]"
                    type="text"
                    component={dgTextArea}
                    label="Enter a description (Optional)"
                    placeholder={"paragraph 2"}
                />
                <Field
                    name="class_details.description[2]"
                    type="text"
                    component={dgTextArea}
                    label="Enter a description (Optional)"
                    placeholder={"paragraph 3"}
                />
                <Field
                    name="class_details.description[3]"
                    type="text"
                    component={dgTextArea}
                    label="Enter a description (Optional)"
                    placeholder={"paragraph 4"}
                />
                <Field
                    name="class_details.description[4]"
                    type="text"
                    component={dgTextArea}
                    label="Enter a description (Optional)"
                    placeholder={"paragraph 5"}
                />
            </>
        ];
        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="form-container">
                        <DgCustomForm
                            innerContainerClass=""
                            headerContainerClass="header"
                            formHeader={<h1>Create Class</h1>}
                            buttonContainerClass="button-container"
                            fields={fields}
                            submitText="Create Class"
                            submitClass="btn-watch"
                            onSubmit={this.onSubmit}
                        />
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    classes: state.classes
});

export default connect(mapStateToProps, {
    createClass
})(CreateClass);
