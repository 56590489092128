import React, { Component } from "react";
import { connect } from "react-redux";

//Actions
import { createVideoSearch } from "../../actions/video.action";

class FilterItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isExpanded: !this.props.isExpanded
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({ isExpanded: !this.state.isExpanded });
    }

    render() {
        return (
            <div className="filterItem-container">
                <div className="header" onClick={() => this.toggle()}>
                        {this.props.title ? this.props.title : ""}
                </div>
                <div
                    className={
                        !this.state.isExpanded ? `body hidden` : `body show`
                    } 
                >
                    {this.props.content ? this.props.content : ""}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    videos: state.videos,
    visibility: state.visible,
    currentVideo: state.videos.currentVideo
});

export default connect(mapStateToProps, {
    createVideoSearch,
})(FilterItem);
