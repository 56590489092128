import {
    VERIFY_PASSWORD_RESET_SUCCESS,
    VERIFY_PASSWORD_RESET_FAILURE,
    PASSWORD_RESET_FAILED,
    PASSWORD_RESET_SUCCESS,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT
} from "./types";
import history from "../history";

export const login = userCredentials => dispatch => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/auth/login`, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(userCredentials)
        })
            .then(res => res.json())
            .then(post => {
                if (!post.ok) {
                    dispatch({
                        type: LOGIN_FAILED,
                        payload: post
                    });

                    reject(post);
                } else {
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: post
                    });

                    resolve(post);
                }
            });
    });
};

export const checkLoggedIn = token => dispatch => {
    return new Promise((resolve, reject) => {
        let body = {token: token};
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/loggedin`, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(body)
        })
            .then(res => res.json())
            .then(post => {
                if (!post.ok) {
                    dispatch({
                        type: LOGIN_FAILED,
                        payload: post
                    });

                    reject(post);
                } else {
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: post
                    });

                    resolve(post);
                }
            });
    });
};

export const logout = () => dispatch => {
    let projectName = `${process.env.REACT_APP_PROJECT_NAME}`;
    localStorage.clear(`${projectName}::access_token`);

    dispatch({
        type: LOGOUT
    });

    history.push("/login");
};

export const resetPassword = form => dispatch => {
    let email = form.email;

    return new Promise((resolve, reject) => {
        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/passwordReset/${email}`,
            {
                method: "GET",
                headers: {
                    "content-type": "application/json"
                }
            }
        )
            .then(res => res.json())
            .then(post => {
                if (!post.ok) {
                    dispatch({
                        type: PASSWORD_RESET_FAILED,
                        payload: post
                    });

                    reject(post);
                } else {
                    dispatch({
                        type: PASSWORD_RESET_SUCCESS,
                        payload: post
                    });

                    resolve(post);
                }
            });
    });
};

export const verifyPasswordReset = () => (dispatch, getState) => {
     return new Promise((resolve, reject) => {
         let data = getState().form.VerifyForm.values;

         fetch(
             `${process.env.REACT_APP_API_URL}/api/v1/users/verifypasswordreset`,
             {
                 method: "POST",
                 headers: {
                     "content-type": "application/json"
                 },
                 body: JSON.stringify(data)
             }
         )
             .then(res => res.json())
             .then(post => {
                 if (!post.ok) {
                     dispatch({
                         type: VERIFY_PASSWORD_RESET_FAILURE,
                         payload: post
                     });
 
                     reject(post);
                 } else {
                     dispatch({
                         type: VERIFY_PASSWORD_RESET_SUCCESS,
                         payload: post
                     });
 
                     resolve(post);
                 }
             });
     });
 };

export function getAccessToken() {
    let token = localStorage.getItem(
        `${process.env.REACT_APP_PROJECT_NAME}::access_token`
    );
    return token;
}
