import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { MultiColumn, TwoColumn } from "dg-blocks";
import { Gallery } from "dg-gallery";

// Styles
import "../styles/pages/class.scss";

//Assets
import twitter from "../assets/icons/twitter.png";
import duration from "../assets/icons/timer.png";
import level from "../assets/icons/level.png";
import size from "../assets/icons/people.png";
import price from "../assets/icons/price-tag.png";
import arrowLeft from "../assets/icons/arrow-left.png";
import arrowRight from "../assets/icons/arrow-right.png";

import gal1 from "../assets/webAssets/gallery/Begimprover1.JPG";
import gal2 from "../assets/webAssets/gallery/Begimprover2.JPG";
import gal3 from "../assets/webAssets/gallery/Begimprover3.JPG";
import gal4 from "../assets/webAssets/gallery/Begimprover4.JPG";
import gal5 from "../assets/webAssets/gallery/Intermediate1.JPG";
import gal6 from "../assets/webAssets/gallery/Intermediate2.JPG";
import gal7 from "../assets/webAssets/gallery/Intermediate3.JPG";
import gal8 from "../assets/webAssets/gallery/Intermediate4.JPG";

// Components
import Header from "../components/header/header.component";
import Footer from "../components/footer/footer.component";

// Actions
import { getClassById } from "../actions/class.action";

const gallery = [
    <>
        <img src={gal1} alt="gallery card" />
    </>,
    <>
        <img src={gal2} alt="gallery card" />
    </>,
    <>
        <img src={gal3} alt="gallery card" />
    </>,
    <>
        <img src={gal4} alt="gallery card" />
    </>,
    <>
        <img src={gal5} alt="gallery card" />
    </>,
    <>
        <img src={gal6} alt="gallery card" />
    </>,
    <>
        <img src={gal7} alt="gallery card" />
    </>,
    <>
        <img src={gal8} alt="gallery card" />
    </>,
];

const items = [
    <>
        <div>
            <p>
                you also have the option to pay in class. if you miss a class,
                you can use this class at a later date, provided you contact me
                beforehand. this means you get full value for your money and you
                don’t miss out.
            </p>
            <p>
                Private One to One Session’s and Corporate Classes also
                available on request. Contact me for further details.
            </p>
        </div>
        <ul>
            <li>
                <h3>Once-off Pilates Class: €13</h3>
                <p> (Class can be taken any time)</p>
            </li>
            <li>
                <h3>2 Classes per Week: €20 </h3>
                <p> (Classes must be within the same week)</p>
            </li>
            <li>
                <h3>5 Class Bundle: €65</h3>
                <p> (Classes can be over various days/weeks)</p>
            </li>
            <li>
                <h3>10 Class Bundle: €111.00</h3>
                <p> (Classes can be over various days/weeks)</p>
            </li>
        </ul>
    </>
];

class Class extends Component {
    componentDidMount() {
        let urlParams = window.location.pathname;
        let params = urlParams.split("/");

        if (params.length >= 3) {
            this.props.getClassById(params[2]).catch(res => {
                Swal.fire("Error", res.message, "error");
            });
        }
    }
    render() {
        return (
            <div className="class-container">
                <Header
                    text={
                        this.props.classes.currentClass &&
                        this.props.classes.currentClass.class_details &&
                        this.props.classes.currentClass.class_details.title &&
                        this.props.classes.currentClass.class_details.title !==
                            ""
                            ? this.props.classes.currentClass.class_details
                                  .title
                            : ""
                    }
                />
                <TwoColumn
                    containerClass="twoCol-container"
                    leftColClass="container-column left"
                    leftColumn={
                        <div className="image-container">
                            {this.props.classes &&
                            this.props.classes.currentClass &&
                            this.props.classes.currentClass.files &&
                            this.props.classes.currentClass.files.files !== ""
                                ? this.props.classes.currentClass.files
                                      .slice(0)
                                      .map((file, index) => (
                                          <img
                                              key={index}
                                              alt="Attached class"
                                              src={file.url}
                                          />
                                      ))
                                : ""}
                        </div>
                    }
                    rightColClass="container-column right"
                    rightColumn={
                        <>
                            <div className="top-container">
                                <h3>
                                    {this.props.classes.currentClass &&
                                    this.props.classes.currentClass
                                        .class_details &&
                                    this.props.classes.currentClass
                                        .class_details.function &&
                                    this.props.classes.currentClass
                                        .class_details.function !== ""
                                        ? this.props.classes.currentClass
                                              .class_details.function
                                        : ""}
                                </h3>
                                <h2>
                                    {this.props.classes.currentClass &&
                                    this.props.classes.currentClass
                                        .class_details &&
                                    this.props.classes.currentClass
                                        .class_details.title &&
                                    this.props.classes.currentClass
                                        .class_details.title !== ""
                                        ? this.props.classes.currentClass
                                              .class_details.title
                                        : ""}
                                </h2>
                                <p>
                                    {this.props.classes.currentClass &&
                                    this.props.classes.currentClass
                                        .class_details &&
                                    this.props.classes.currentClass
                                        .class_details.summary &&
                                    this.props.classes.currentClass
                                        .class_details.summary !== ""
                                        ? this.props.classes.currentClass
                                              .class_details.summary
                                        : ""}
                                </p>
                            </div>
                            <div className="info-container">
                                <hr />
                                <div className="row">
                                    <div>
                                        <img
                                            src={duration}
                                            alt="class duration"
                                        />
                                        <p>DURATION</p>
                                    </div>
                                    <div>
                                        <strong>
                                            {this.props.classes.currentClass &&
                                            this.props.classes.currentClass
                                                .class_details &&
                                            this.props.classes.currentClass
                                                .class_details.duration &&
                                            this.props.classes.currentClass
                                                .class_details.duration !== ""
                                                ? `${this.props.classes.currentClass.class_details.duration} MINS`
                                                : ""}
                                        </strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <div>
                                        <img src={level} alt="class level" />
                                        <p>LEVEL</p>
                                    </div>
                                    <div>
                                        <strong>
                                            {this.props.classes.currentClass &&
                                            this.props.classes.currentClass
                                                .class_details &&
                                            this.props.classes.currentClass
                                                .class_details.level &&
                                            this.props.classes.currentClass
                                                .class_details.level !== ""
                                                ? this.props.classes
                                                      .currentClass
                                                      .class_details.level
                                                : ""}
                                        </strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <div>
                                        <img src={size} alt="max size" />
                                        <p>SIZE</p>
                                    </div>
                                    <div>
                                        <strong>
                                            {this.props.classes.currentClass &&
                                            this.props.classes.currentClass
                                                .class_details &&
                                            this.props.classes.currentClass
                                                .class_details.size &&
                                            this.props.classes.currentClass
                                                .class_details.size !== ""
                                                ? this.props.classes
                                                      .currentClass
                                                      .class_details.size
                                                : ""}
                                        </strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <div>
                                        <img src={price} alt="cost of class" />
                                        <p>PRICE</p>
                                    </div>
                                    <div>
                                        <strong>
                                            {this.props.classes.currentClass &&
                                            this.props.classes.currentClass
                                                .class_details &&
                                            this.props.classes.currentClass
                                                .class_details.price &&
                                            this.props.classes.currentClass
                                                .class_details.price !== ""
                                                ? this.props.classes
                                                      .currentClass
                                                      .class_details.price
                                                : ""}
                                        </strong>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <div className="buttons-container">
                                <button className="btn-twitter">
                                    <NavLink exact={true} to="/">
                                        <img
                                            src={twitter}
                                            alt="view timetable"
                                        />{" "}
                                        TIMETABLE
                                    </NavLink>
                                </button>
                                <button className="btn-watch">
                                    <NavLink exact={true} to="/video-classes">
                                        BOOK A CLASS
                                    </NavLink>
                                </button>
                            </div>
                        </>
                    }
                />
                <MultiColumn
                    header={<h1>Class Description</h1>}
                    posts={
                        this.props.classes.currentClass &&
                        this.props.classes.currentClass.class_details &&
                        this.props.classes.currentClass.class_details
                            .description &&
                        this.props.classes.currentClass.class_details
                            .description !== ""
                            ? this.props.classes.currentClass.class_details.description.map(
                                  (desc, index) =>
                                      desc !== "" ? (
                                          <div className="paragraph">
                                              <p key={`${index}`}>{desc}</p>
                                          </div>
                                      ) : (
                                          ""
                                      )
                              )
                            : ""
                    }
                    errorClass={"error-container"}
                    errorMessage={<h3>No Content Available</h3>}
                />
                <MultiColumn
                    posts={items}
                    errorClass={"error-container"}
                    errorMessage={<h3>No Content Available</h3>}
                />
                <Gallery
                    header={<h1>Photo Gallery</h1>}
                    arrowLeft={arrowLeft}
                    arrowRight={arrowRight}
                    gallery={gallery}
                />
                <Footer />
            </div>
        );
    }
}
const mapStateToProps = state => ({
    classes: state.classes
});

export default connect(mapStateToProps, {
    getClassById
})(Class);
