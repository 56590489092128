import React, { Component } from "react";
import { MultiColumn, TwoColumn } from "dg-blocks";

// Styles
import "../styles/pages/about.scss";

//Assets
import colm from "../assets/webAssets/colm.jpg";

// Components
import Header from "../components/header/header.component";
import Footer from "../components/footer/footer.component";

const items = [
    <>
        <h1>Story and Vision</h1>
        <p>
            My mission is to successfully incorporate Pilates into people’s
            lives, using a modernised style to suit all fitness backgrounds;
            increasing their quality of health and lifestyle as a result. Some
            have tried Pilates and didn’t like it or maybe hurt themselves.
            Remember, although it’s great to do exercise you enjoy, sometimes we
            need to do exercise that our body enjoys!
        </p>
        <p>
            Regardless of your fitness ability, I will teach you to strengthen,
            lengthen, redefine and realign your body and mind.
        </p>
    </>
];
class About extends Component {
    render() {
        return (
            <div className="about-container">
                <Header text="ABOUT US" />
                <MultiColumn
                    posts={items}
                    errorClass={"error-container"}
                    errorMessage={<h3>No Content Available</h3>}
                />
                <TwoColumn
                    leftColumn={
                        <>
                            <img
                                src={colm}
                                className="trainer-image"
                                alt="colm-trainer"
                            />
                        </>
                    }
                    rightColClass="container-column right"
                    rightColumn={
                        <>
                            <h1>About Colm</h1>
                            <p>
                                After graduating from college, I began working
                                in the fitness, health and wellbeing industry,
                                where I have spent the last 17 years; the
                                majority of my experience gained while based in
                                and around the University of Limerick Sports
                                Arena.
                            </p>
                            <p>
                                My journey as a Pilates instructor began in
                                2014, when I completed a Diploma in Mat Pilates
                                with BodyFirm Progressive Pilates Academy in
                                Dublin. Here, I studied under Master Coach,
                                Joyce Gavin. Joyce is a pioneer in core training
                                and functional movement and is credited with
                                introducing the Pilates technique to Ireland in
                                the 90’s.
                            </p>
                            <p>
                                Following my training, I set up my own Pilates
                                classes and have been teaching for over 5 years.
                                Throughout this time, I have developed a unique
                                approach to movement; incorporating the original
                                Pilates method and utilising my training as a
                                Physical Therapist, to achieve the maximum
                                results. I am passionate about teaching Pilates
                                and passionate about its benefits; continuously
                                delivering a bespoke Pilates experience to my
                                clients.
                            </p>
                        </>
                    }
                />
                <Footer />
            </div>
        );
    }
}
export default About;
