import {
    CONTACT_SUBMIT,
    GET_CONTACTS,
} from "../actions/types";

const initialState = {
    contacts: [],
    currentContact: {},
    message: "",
    ok: true,
    errors: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CONTACTS:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                contacts: action.payload.contacts
            };
        case CONTACT_SUBMIT:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                currentContact: action.payload.contact,
            };
        default:
            return state;
    }
}
