import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";

// Styles
import "../../../styles/components/admin/admin.scss";
import "../../../styles/components/admin/view.scss";

// Actions
import { getContactById } from "../../../actions/contact.action";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";

class ViewContact extends Component {
    componentDidMount() {
        let urlParams = window.location.pathname;
        let params = urlParams.split("/");

        if (params.length >= 4) {
            this.props.getContactById(params[3]).catch(res => {
                Swal.fire("Error", res.message, "error");
            });
        }
    }

    render() {
        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="view-container">
                        <div className="item">
                            <h1>Contact Details</h1>
                        </div>
                        <div className="card">
                            <div className="card-top">
                                <h2>
                                    <strong>User Details:</strong>
                                </h2>{" "}
                            </div>
                            <div className="card-bottom header">
                                <div className="col">
                                    <h3>
                                        <strong>Name:</strong>{" "}
                                        {this.props.contacts.currentContact &&
                                        this.props.contacts.currentContact
                                            .your_details &&
                                        this.props.contacts.currentContact
                                            .your_details.name &&
                                        this.props.contacts.currentContact
                                            .your_details.name !== ""
                                            ? this.props.contacts.currentContact
                                                  .your_details.name
                                            : ""}
                                    </h3>
                                    <h3>
                                        <strong>Email:</strong>{" "}
                                        {this.props.contacts.currentContact &&
                                        this.props.contacts.currentContact
                                            .your_details &&
                                        this.props.contacts.currentContact
                                            .your_details.email &&
                                        this.props.contacts.currentContact
                                            .your_details.email !== ""
                                            ? this.props.contacts.currentContact
                                                  .your_details.email
                                            : ""}
                                    </h3>
                                </div>
                                <div className="col">
                                    <h3>
                                        <strong>Phone:</strong>{" "}
                                        {this.props.contacts.currentContact &&
                                        this.props.contacts.currentContact
                                            .your_details &&
                                        this.props.contacts.currentContact
                                            .your_details.phone &&
                                        this.props.contacts.currentContact
                                            .your_details.phone !== ""
                                            ? this.props.contacts.currentContact
                                                  .your_details.phone
                                            : ""}
                                    </h3>
                                    <h3>
                                        <strong>Reason:</strong>{" "}
                                        {this.props.contacts.currentContact &&
                                        this.props.contacts.currentContact
                                            .your_details &&
                                        this.props.contacts.currentContact
                                            .your_details.reason &&
                                        this.props.contacts.currentContact
                                            .your_details.reason !== ""
                                            ? this.props.contacts.currentContact
                                                  .your_details.reason
                                            : ""}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-top">
                                <h2>
                                    <strong>Message:</strong>
                                </h2>{" "}
                            </div>
                            <div className="card-bottom">
                                <h3>
                                    {this.props.contacts.currentContact &&
                                    this.props.contacts.currentContact
                                        .your_details &&
                                    this.props.contacts.currentContact
                                        .your_details.message &&
                                    this.props.contacts.currentContact
                                        .your_details.message !== ""
                                        ? this.props.contacts.currentContact
                                              .your_details.message
                                        : ""}
                                </h3>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    contacts: state.contacts
});

export default connect(mapStateToProps, {
    getContactById
})(ViewContact);
