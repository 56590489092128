import React, { Component } from "react";

//Styles
import "../../styles/components/filters/filterItem.scss";

// Components
import FilterItem from "./filterItemAlt.component";

class Filters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: []
        };

        this.selectTopic = this.selectTopic.bind(this);
    }

    componentWillMount() {
        this.setState({ filters: this.props.filters });
    }

    selectTopic(e) {
        const topic = e.target.value;

        let list = this.props.filters;

        if (topic !== "") {
            list = list.filter(item => item.topic === topic);
        }

        this.setState({ filters: list });
    }

    render() {
        const renderFilters = this.props.filters.map((item, index, array) => (
            <FilterItem
                key={`filter_item_${index}`}
                title={<h3>{item.title}</h3>}
                content={<div className="content">{item.content}</div>}
                status={true}
            />
        ));

        return (
            <div className="filter-container">
                <div className="filters-header">
                    <h3>VIDEO CATEGORIES</h3>
                    <button
                        className="clear-filters"
                        onClick={this.props.clearFilter}
                    >
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="far"
                            data-icon="times-circle"
                            class="svg-inline--fa fa-times-circle fa-w-16"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <path
                                className="primary-color"
                                fill="currentColor"
                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
                            ></path>
                        </svg>
                    </button>
                </div>
                {renderFilters}
            </div>
        );
    }
}

export default Filters;
