import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import history from "../../history";

// Styles
import "../../styles/components/login/login.scss";

// Assets

// Components
import VerifyPasswordResetForm from "./verifyForm.component";
import Footer from "../footer/footer.component";
import Header from "../header/header.component";

// Actions
import { verifyPasswordReset } from "../../actions/login.action";

class PasswordReset extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(form) {
        this.props
            .verifyPasswordReset(form)
            .then(res => {
                history.push("/login");
            })
            .catch(res => {
                Swal.fire(`Error`, res.message, "error");
            });
    }

    render() {
        return (
            <div className="login-container">
                <Header text="RESET PASSWORD" />
                <div className="form-container">
                    <VerifyPasswordResetForm onSubmit={this.onSubmit} />
                </div>
                <Footer />
            </div>
        );
    }
}

PasswordReset.propTypes = {
    verifyPasswordReset: PropTypes.func.isRequired
};

export default connect(null, { verifyPasswordReset })(PasswordReset);
