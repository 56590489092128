import React, { Component } from "react";
import { connect } from "react-redux";
import { visible } from "../../actions/visible.action";

import "../../styles/components/video/video.scss";

class Video extends Component {
    render() {
        return (
            <div className="video-container">
                <button className="video-close" onClick={this.props.visible}>
                    {" "}
                    x{" "}
                </button>
                {/* <iframe id="header-video" src={`https://player.vimeo.com/video/${this.props.video}?autoplay=1&loop=1&color=6AD2D4&title=0&byline=0&portrait=0`}  frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                 */}
                    <iframe
                        title="video title"
                    width="90%"
                    height="90%"
                    src={`https://www.youtube.com/embed/${this.props.video}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
        );
    }
}

export default connect(null, { visible })(Video);
