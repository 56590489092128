import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Pagination } from "dg-pagination";
import { Card, MultiColumn, TwoColumn } from "dg-blocks";

// Styles
import "../styles/pages/members.scss";
import "../styles/components/filters/slider.scss";

//Assets
import legitLogo from "../assets/webAssets/legitfit-logo.png";
import tablet from "../assets/webAssets/tablet.png";
import duration from "../assets/icons/timer.png";
import play from "../assets/icons/yt-play.png";

//Actions
import {
    generateFilters,
    getVideos,
    setFilters,
    setQuery,
    createVideoSearch,
    setSliderValues,
    setInitialSliderValues
} from "../actions/video.action";
import { visible } from "../actions/visible.action";

// Components
import Header from "../components/header/header.component";
import Footer from "../components/footer/footer.component";
import Video from "../components/video/video.component";
import Filters from "../components/filters/filtersAlt.component";
import DoubleSlider from './slider';

class Members extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            page_size: 6,
            total_pages: 0,
            total_items: 0,
            videoUrl: null
        };

        this.setVideo = this.setVideo.bind(this);
        this.paginate = this.paginate.bind(this);
        this.updateQuery = this.updateQuery.bind(this);
        this.onSliderChange = this.onSliderChange.bind(this);
        this.onClear = this.onClear.bind(this);
    }

    setVideo(id) {
        //parse id to take just id of video url
        let embedded = id.split("/");
        let string = embedded[embedded.length - 1];
        console.log(id);
        console.log(embedded);
        console.log(string);
        this.setState({ videoUrl: string });
        this.props.visible();
    }

    onClear() {
        this.props.setQuery({});
        this.props
            .createVideoSearch(0, 0, {})
            .then(res => {
                this.props.setSliderValues([...this.props.videos.initialSliderValues]);
                this.setState({
                    page: res.videos.page,
                    page_size: res.videos.page_size,
                    total_pages: res.videos.total_pages,
                    total_items: res.videos.total_items
                });
            })
            .catch(res => {});
    }

    onSliderChange(values, key) {
        console.log(values, key, this.state);
        this.props.setSliderValues([...values]);

        let queryArr = [
            {
                key: `video_details.${key}`,
                operator: "GTE",
                value: values[0]
            },
            {
                key: `video_details.${key}`,
                operator: "LTE",
                value: values[1]
            }
        ];

        this.updateQuery(`video_details.${key}`, queryArr);
    }
    updateQuery(key, queryObject) {
        let tmp = this.props.query;
        tmp[key] = queryObject;

        console.log(tmp);

        this.props.setQuery(tmp).then(data => {
            this.props
                .createVideoSearch(
                    this.state.page,
                    this.state.page_size,
                    this.props.query
                )
                .then(res => {
                    this.setState({
                        page: res.videos.page,
                        page_size: res.videos.page_size,
                        total_pages: res.videos.total_pages,
                        total_items: res.videos.total_items
                    });
                })
                .catch(res => {});
        });
    }

    componentDidMount() {
        let collections = [
            {
                name: "duration",
                type: "Slider",
                conditions: [
                    {
                        key: "video_details.duration",
                        operator: "Any"
                    }
                ]
            },
            {
                name: "category",
                type: "List",
                conditions: [
                    {
                        key: "video_details.category",
                        operator: "Any"
                    }
                ]
            }
        ];
        this.props
            .generateFilters({ collections: collections })
            .then(res => {
                let filters = [];

                res.filters.products.forEach((value, index) => {
                    let obj = {};
                    switch (collections[index].type) {
                        case "Slider":
                            obj.title =
                                collections[index].name
                                    .charAt(0)
                                    .toUpperCase() +
                                collections[index].name.slice(1);
                            obj.isExpanded = false;

                            this.props.setSliderValues([Math.min(...value), Math.max(...value)]);
                            this.props.setInitialSliderValues([Math.min(...value), Math.max(...value)]);

                            obj.content = (
                                <DoubleSlider
                                    index={index} 
                                    collections={collections}
                                    onChange={this.onSliderChange}
                                />
                            );

                            
                            break;
                        case "List":
                            obj.title =
                                collections[index].name
                                    .charAt(0)
                                    .toUpperCase() +
                                collections[index].name.slice(1);
                            obj.isExpanded = false;
                            obj.content = [];
                            value.forEach(listValue => {
                                obj.content.push(
                                    <div
                                        onClick={() => {
                                            this.updateQuery(
                                                `video_details.${collections[index].name}`,
                                                {
                                                    key: `video_details.${collections[index].name}`,
                                                    operator: "Equals",
                                                    value: listValue
                                                }
                                            );
                                        }}
                                        className="item"
                                    >
                                        <h4>{listValue}</h4>
                                    </div>
                                );
                            });
                            break;

                        default:
                            break;
                    }
                    filters.push(obj);
                });
                this.props.setFilters(filters);
                this.paginate(this.state.page, this.state.page_size);
            })
            .catch(res => {
                Swal.fire(`Error`, res.message, "error");
            });

        this.onClear();
    }

    paginate(page, page_size) {
        this.props
            .createVideoSearch(page, page_size, this.props.query)
            .then(res => {
                this.setState({
                    page: res.videos.page,
                    page_size: res.videos.page_size,
                    total_pages: res.videos.total_pages,
                    total_items: res.videos.total_items
                });
            })
            .catch(res => {});
    }

    render() {
        let videos = [];
        if (
            this.props.videos &&
            this.props.videos.videos &&
            this.props.videos.videos.length > 0
        ) {
            videos = this.props.videos.videos.map((item, index) => {
                return (
                    <Card
                        cardImage={
                            <div
                                className="upper-container"
                                onClick={() =>
                                    this.setVideo(item.video_details.url)
                                }
                            >
                                {item.files.slice(0).map((item, index) => (
                                    <img
                                        className="thumbnail"
                                        alt="attached class"
                                        src={item.url}
                                    />
                                ))}
                                <img
                                    className="youtube"
                                    alt="youtube play btn"
                                    src={play}
                                />
                            </div>
                        }
                        cardBody={
                            <>
                                <h3>{item.video_details.category}</h3>
                                <h2>{item.video_details.title}</h2>
                                <p>{item.video_details.summary}</p>
                                <div className="info-container">
                                    <div>
                                        <img
                                            src={duration}
                                            alt="class duration"
                                        />
                                        <p> DURATION</p>
                                    </div>
                                    <div>
                                        <strong>
                                            {`${item.video_details.duration} MINS`}
                                        </strong>
                                    </div>
                                </div>
                            </>
                        }
                    />
                );
            });
        }
        return (
            <div className="members-container">
                <Header text="VIDEO CLASSES" />
                <div className="container-row">
                    <div className="container-col left">
                        <Filters
                            filters={this.props.filters}
                            clearFilter={this.onClear}
                        />
                    </div>
                    <div className="container-col right">
                        <MultiColumn
                            containerClass={"multiCol-container"}
                            posts={videos}
                            rowClass={"container-row"}
                            columnClass={"column"}
                            errorClass={"error-container"}
                            errorMessage={
                                <>
                                    <h3>No Videos Available</h3>
                                    <p>
                                        We're sorry, there doesn't seem to be
                                        any video classes available at the
                                        moment!
                                    </p>
                                </>
                            }
                        />

                        {this.state.total_pages > 1 ? (
                            <Pagination
                                type="Standard"
                                paginate={this.paginate}
                                page_size={this.state.page_size}
                                page={this.state.page}
                                total_pages={this.state.total_pages}
                                total_items={this.state.total_items}
                            />
                        ) : (
                            ""
                        )}

                        {this.props.visibility && (
                            <Video video={this.state.videoUrl} />
                        )}
                    </div>
                </div>
                <TwoColumn
                    containerClass="twoCol-container"
                    rightColClass="container-column right"
                    rightColumn={
                        <>
                            <img
                                src={tablet}
                                className="mobile-image"
                                alt="legitfit tablet"
                            />
                        </>
                    }
                    leftColClass="container-column left"
                    leftColumn={
                        <>
                            <img
                                src={legitLogo}
                                className="legit-image"
                                alt="legitfit mobile logo"
                            />
                            <h1>Legitfit</h1>
                            <p>
                                Fitness management solution that helps fitness
                                entrepreneurs streamline their day and manage
                                customer schedules and payments.
                            </p>
                            <p>Some of the amazing features:</p>
                            <ul>
                                <li>Private Dashboard</li>
                                <li>Shared Timetable with Clients</li>
                                <li>Take Bookings and Payments Online</li>
                                <li>Track Your Business and Clients</li>
                                <li>Sell Packages and Memberships</li>
                            </ul>
                            <button className="btn-watch">LEARN MORE</button>
                        </>
                    }
                />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    videos: state.videos,
    visibility: state.visible,
    currentVideo: state.videos.currentVideo,
    filters: state.videos.filters,
    query: state.videos.query
});

export default connect(mapStateToProps, {
    getVideos,
    generateFilters,
    createVideoSearch,
    setFilters,
    setQuery,
    visible,
    setSliderValues,
    setInitialSliderValues
})(Members);
