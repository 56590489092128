import {
    GET_CLASSES_FAILED,
    GET_CLASSES_SUCCESS,
    FETCH_CLASS,
    CREATE_CLASS,
    DELETE_CLASS,
    SET_CURRENT_CLASS
} from "./types";
import { getAccessToken } from "./login.action";

export const createClass = data => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/classes/create`, {
            method: "POST",
            body: data
        })
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: CREATE_CLASS,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};


export const getClasses = (page, page_size) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/classes/?page=${page}&page_size=${page_size}`,
            {
                method: "GET",
                headers: {
                    "content-type": "application/json"
                }
            }
        )
            .then(res => res.json())
            .then(post => {
                if (!post.ok) {
                    dispatch({
                        type: GET_CLASSES_FAILED,
                        payload: post
                    });

                    reject(post);
                } else {
                    dispatch({
                        type: GET_CLASSES_SUCCESS,
                        payload: post
                    });

                    resolve(post);
                }
            });
    });
};

export const getClassById = id => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/classes/get/${id}`, {
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(res => res.json())
            .then(post => {
                if (!post.ok) {
                    dispatch({
                        type: FETCH_CLASS,
                        payload: post
                    });

                    reject(post);
                } else {
                    dispatch({
                        type: FETCH_CLASS,
                        payload: post
                    });

                    resolve(post);
                }
            });
    });
};

export const deleteClass = classId => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let token = getAccessToken();
        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/classes/delete/${classId}`,
            {
                method: "DELETE",
                headers: {
                    "content-type": "application/json",
                    access_token: token
                }
            }
        )
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: DELETE_CLASS,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const updateClass = (classId, data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let token = getAccessToken();

        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/classes/update/${classId}`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json",
                    access_token: token
                },
                body: JSON.stringify(data)
            }
        )
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: CREATE_CLASS,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const setCurrentClass = data => dispatch => {
    dispatch({
        type: SET_CURRENT_CLASS,
        payload: data
    });
};
