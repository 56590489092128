export const FETCH_POSTS = "FETCH_POSTS";
export const NEW_POST = "NEW_POST";
export const SELECT_POST = "SELECT_POST";
export const CLEAR_POST_FORM = "CLEAR_POST_FORM";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";

//Users
export const CREATE_USER = "CREATE_USER";
export const DELETE_USER = "DELETE_USER";
export const GET_USERS = "GET_USERS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

//Contact
export const CONTACT_SUBMIT = "CONTACT_SUBMIT";
export const GET_CONTACTS = "GET_CONTACTS";
export const DELETE_CONTACT = "DELETE_CONTACT";

//Classes
export const FETCH_CLASS = "FETCH_CLASS";
export const GET_CLASSES_FAILED = "GET_CLASSES_FAILED";
export const GET_CLASSES_SUCCESS = "GET_CLASSES_SUCCESS";
export const SET_CURRENT_CLASS = "SET_CURRENT_CLASS";
export const SET_IS_CLASS_LOADING = "SET_IS_CLASS_LOADING";
export const CREATE_CLASS = "CREATE_CLASS";
export const DELETE_CLASS = "DELETE_CLASS";

//Videos
export const FETCH_VIDEO = "FETCH_VIDEO";
export const GET_VIDEOS_FAILED = "GET_VIDEOS_FAILED";
export const GET_VIDEOS_SUCCESS = "GET_VIDEOS_SUCCESS";
export const SET_CURRENT_VIDEO = "SET_CURRENT_VIDEO";
export const SET_IS_VIDEO_LOADING = "SET_IS_VIDEO_LOADING";
export const CREATE_VIDEO = "CREATE_VIDEO";
export const DELETE_VIDEO = "DELETE_VIDEO";
export const SEARCH_VIDEOS_FAILED = "SEARCH_VIDEOS_FAILED";
export const SEARCH_VIDEOS_SUCCESS = "SEARCH_VIDEOS_SUCCESS";
export const SET_SLIDER_VALUES = 'SET_SLIDER_VALUES';
export const SET_INITIAL_SLIDER_VALUES = 'SET_INITIAL_SLIDER_VALUES';

//Filters
export const GENERATE_FILTERS_SUCCESS = "GENERATE_FILTERS_SUCCESS";
export const GENERATE_FILTERS_FAILED = "GENERATE_FILTERS_FAILED";
export const SET_FILTERS = "SET_FILTERS";
export const SET_QUERY = "SET_QUERY";

//Visible
export const VISIBLE = "VISIBLE";

//PASSWORD RESET
export const PASSWORD_RESET_FAILED = "PASSWORD_RESET_FAILED";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const VERIFY_PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const VERIFY_PASSWORD_RESET_FAILURE = "PASSWORD_RESET_SUCCESS";

