import closeIcon from "../icons/close_button.svg";
import logo from "../icons/logo-home.png";
import menuIcon from "../icons/menu_icon.svg";

const mobileNav = {
    sideDrawerItems: [
        {
            title: "HOME",
            link: "/"
        },
        {
            title: "CLASSES",
            link: "/classes"
        },
        {
            title: "VIDEO CLASSES",
            link: "/video-classes"
        },
        {
            title: "ABOUT US",
            link: "/about"
        },
        {
            title: "CONTACT US",
            link: "/contact"
        }
    ],
    title: "HanleyFit",
    menuIcon: menuIcon,
    closeIcon: closeIcon,
    logo: logo
};

export default mobileNav;
