import React, { Component } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Jumbotron } from "dg-jumbotron";
import { TwoColumn, MultiColumn, Card } from "dg-blocks";
import { TestimonialTimer } from "dg-testimonials";

// Styles
import "../styles/pages/home.scss";

//Assets
import play from "../assets/icons/play-btn.png";
import woman from "../assets/webAssets/woman-stretching.png";
import shape from "../assets/webAssets/shapes-about.png";
import woman2 from "../assets/webAssets/woman-CTA.png";
import shape2 from "../assets/webAssets/shapes-cta.png";
import quoteIcon from "../assets/icons/quotesbg.png";

// Components
import Navbar from "../components/navbar/navbarAlt.component";
import Footer from "../components/footer/footer.component";
import testimonials from "../assets/files/testimonials";
import testimonialsMobile from "../assets/files/testimonialsMobile";

//Actions
import { logout } from "../actions/login.action";
import { getVideos } from "../actions/video.action";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            isProfileMenuOpen: false,
            page: 0,
            page_size: 6,
            total_pages: 0,
            total_items: 0,
            videoUrl: null
        };

        this.onLogout = this.onLogout.bind(this);
        this.isLoggedIn = this.isLoggedIn.bind(this);
    }
    setVideo(id) {
        //parse id to take just id of video url
        let embedded = id.split("=")[1];
        console.log(embedded);
        this.setState({ videoUrl: embedded });
        this.props.visible();
    }
    onLogout(e) {
        e.preventDefault();

        this.props.logout();
    }

    isLoggedIn() {
        if (this.props.user.user.isActive) {
            this.setState(state => {
                return { loggedIn: true };
            });
        } else {
            this.setState(state => {
                return { loggedIn: false };
            });
        }
    }

    componentDidMount() {
        this.paginate(this.state.page, this.state.page_size);
        this.isLoggedIn();
    }

    paginate(page, page_size) {
        this.props
            .getVideos(page, page_size)
            .then(res => {
                this.setState({
                    page: res.page,
                    page_size: res.page_size,
                    total_pages: res.total_pages,
                    total_items: res.total_items
                });
            })
            .catch(res => {
                Swal.fire(`Error`, res.message, "error");
            });
    }

    render() {
        let videos = [];

        if (
            this.props.videos &&
            this.props.videos.videos &&
            this.props.videos.videos.length > 0
        ) {
            videos = this.props.videos.videos.slice(0, 3).map((item, index) => {
                return (
                    <Card
                        cardImage={item.files.slice(0).map((item, index) => (
                            <>
                                <img
                                    className="thumbnail"
                                    alt="attached class"
                                    src={item.url}
                                />
                                <button className="btn-watch">
                                    <NavLink exact={true} to="/members">
                                        <img src={play} alt="view videos" />{" "}
                                        WATCH VIDEO CLASSES
                                    </NavLink>
                                </button>
                            </>
                        ))}
                        cardBody={
                            <>
                                <h2>{item.video_details.title}</h2>
                                <p>{item.video_details.summary}</p>
                            </>
                        }
                    />
                );
            });
        }
        return (
            <div className="home-container">
                <div className="header-container">
                    <Navbar />
                    <Jumbotron
                        containerClass="Jumbotron-container"
                        jumbotronClass="jumbotron"
                        showDivider={false}
                        bodyClass="body"
                        body={
                            <div className="content">
                                <h2>
                                    STRENGTHEN, LENGTHEN
                                    <br />
                                    <strong>RE-DEFINE, RE-ALIGN</strong>
                                </h2>
                                <p>
                                    Access streaming Pilates videos from the
                                    comfort of home
                                </p>
                                <button className="btn-watch">
                                    <NavLink exact={true} to="/members">
                                        <img src={play} alt="view videos" />{" "}
                                        WATCH VIDEO CLASSES
                                    </NavLink>
                                </button>
                            </div>
                        }
                    />
                </div>
                <TwoColumn
                    leftColumn={
                        <>
                            <img
                                src={woman}
                                className="woman-image"
                                alt="woman stretching"
                            />
                            <img
                                src={shape}
                                className="background-image"
                                alt="background shape"
                            />
                        </>
                    }
                    rightColClass="container-column right"
                    rightColumn={
                        <>
                            <h1>About Us</h1>
                            <p>
                                I have developed a unique approach to movement;
                                incorporating the original Pilates method and
                                utilising my training as a Physical Therapist,
                                to achieve the maximum results. I am passionate
                                about teaching Pilates and passionate about its
                                benefits; continuously delivering a bespoke
                                Pilates experience to my clients.
                            </p>
                            <NavLink exact={true} to="/about">
                                <button className="btn-watch">
                                    LEARN MORE
                                </button>
                            </NavLink>
                        </>
                    }
                />
                <MultiColumn
                    containerClass={"multiCol-container"}
                    headerClass={"multiCol-header"}
                    header={
                        <>
                            <h1>Our Pilates Workouts</h1>
                            <h4>Find the Right Class for You</h4>
                        </>
                    }
                    posts={videos}
                    rowClass={"container-row"}
                    columnClass={"column"}
                    footerClass={"container-bottom"}
                    footer={
                        <NavLink exact={true} to="/members">
                            <button className="btn-watch">
                                VIEW ALL CLASSES
                            </button>
                        </NavLink>
                    }
                    errorClass={"error-container"}
                    errorMessage={
                        <>
                            <h3>No Classes Available</h3>
                            <p>
                                We're sorry, there doesn't seem to be any video
                                classes available at the moment!
                            </p>
                        </>
                    }
                />
                <div className="mobile-testimonial">
                    <TestimonialTimer
                        containerClass="testimonials-container"
                        innerContainerClass="testimonials"
                        bodyClass="testimonial-body"
                        body={testimonialsMobile}
                        quoteIcon={<img src={quoteIcon} alt="quote icon" />}
                        intervalTime={100}
                    />
                </div>
                <div className="desktop-testimonial">
                    <TestimonialTimer
                        containerClass="testimonials-container"
                        innerContainerClass="testimonials"
                        bodyClass="testimonial-body"
                        body={testimonials}
                        quoteIcon={<img src={quoteIcon} alt="quote icon" />}
                        intervalTime={100}
                    />
                </div>
                <TwoColumn
                    containerClass="twoCol-container-alt"
                    leftColClass="container-column left"
                    leftColumn={
                        <>
                            <h1>Join A Class Today!</h1>
                            <h4>Find the Right Class for You</h4>
                            <button className="btn-watch">
                                <NavLink exact={true} to="/members">
                                    <img src={play} alt="view videos" /> WATCH
                                    VIDEO CLASSES
                                </NavLink>
                            </button>
                        </>
                    }
                    rightColClass="container-column"
                    rightColumn={
                        <>
                            <img
                                src={woman2}
                                className="woman-image"
                                alt="woman stretching"
                            />
                            <img
                                src={shape2}
                                className="background-image"
                                alt="background shape"
                            />
                        </>
                    }
                />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    videos: state.videos,
    user: state.loggedInUser
});

Home.propTypes = {
    onLogout: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { getVideos, logout })(Home);
