import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import history from "../../history";

// Styles
import "../../styles/components/login/login.scss";

// Components
import LoginForm from "./loginForm.component";
import ResetForm from "./resetForm.component";
import Footer from "../footer/footer.component";
import Header from "../header/header.component";

// Actions
import { resetPassword, login } from "../../actions/login.action";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1
        };
        this.nextPage = this.nextPage.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    nextPage() {
        this.setState({ page: this.state.page + 1 });
    }

    resetPassword(form) {
        this.props
            .resetPassword(form)
            .then(res => {
                this.nextPage();
            })
            .catch(res => {
                this.nextPage();
            });
    }

    onSubmit(form) {
        let projectName = `${process.env.REACT_APP_PROJECT_NAME}`;

        this.props
            .login(form)
            .then(res => {
                localStorage.setItem(
                    `${projectName}::access_token`,
                    res.access_token
                );
                if (res.user.isAdmin) {
                    history.push("/admin/users");
                } else {
                    history.push("/");
                }
            })
            .catch(res => {
                alert(res.message);
            });
    }

    render() {
        return (
            <div className="login-container">
                <Header text="Welcome To HanleyFit" />
                <div className="form-container">
                    {this.state.page === 1 && (
                        <LoginForm
                            onSubmit={this.onSubmit}
                            forgotPassword={this.nextPage}
                        />
                    )}
                    {this.state.page === 2 && (
                        <ResetForm onSubmit={this.resetPassword} />
                    )}
                    {this.state.page === 3 && (
                        <form className="success-form">
                            <div className="header">
                                <h1>Success</h1>
                            </div>
                            <div className="body">
                                <p>
                                    If there is an account with that email in
                                    our system, we'll send it an email detailing
                                    how to reset the password!
                                </p>
                            </div>
                        </form>
                    )}
                </div>
                <Footer />
            </div>
        );
    }
}

Login.propTypes = {
    login: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired
};

export default connect(null, { login, resetPassword })(Login);
