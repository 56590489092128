import {
    GET_CLASSES_FAILED,
    FETCH_CLASS,
    SET_CURRENT_CLASS,
    SET_IS_CLASS_LOADING,
    GET_CLASSES_SUCCESS
} from "../actions/types";

const initialState = {
    message: "",
    ok: true,
    error: [],
    classes: [],
    currentClass: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_CLASS: 
            return {
                ...state,
                currentClass: action.payload
        }
        case GET_CLASSES_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                classes: action.payload.classes,
                message: action.payload.message
            };
        case GET_CLASSES_FAILED:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message
            };
        case SET_IS_CLASS_LOADING:
            return {
                ...state,
                isClassLoading: action.payload
            };
        case FETCH_CLASS:
            return {
                ...state,
                currentClass: action.payload.workoutClass,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message
            };
        default:
            return state;
    }
}
