import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";

// Styles
import "../../../styles/components/admin/admin.scss";
import "../../../styles/components/admin/view.scss";

// Actions
import { getUserById } from "../../../actions/users.action";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";

class ViewUser extends Component {
    componentDidMount() {
        let urlParams = window.location.pathname;
        let params = urlParams.split("/");

        if (params.length >= 4) {
            this.props.getUserById(params[3]).catch(res => {
                Swal.fire("Error", res.message, "error");
            });
        }
    }

    render() {
        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="view-container">
                        <div className="item">
                            <h1>User Details</h1>
                        </div>
                        <div className="card">
                            <div className="card-top">
                                <h2>
                                    <strong>User Details:</strong>
                                </h2>{" "}
                            </div>
                            <div className="card-bottom header">
                                <div className="col">
                                    <h3>
                                        <strong>User Name:</strong>{" "}
                                        {this.props.users.currentUser &&
                                        this.props.users.currentUser.username &&
                                        this.props.users.currentUser
                                            .username !== ""
                                            ? this.props.users.currentUser
                                                  .username
                                            : ""}
                                    </h3>
                                    <h3>
                                        <strong>Email:</strong>{" "}
                                        {this.props.users.currentUser &&
                                        this.props.users.currentUser.email &&
                                        this.props.users.currentUser.email !==
                                            ""
                                            ? this.props.users.currentUser.email
                                            : ""}
                                    </h3>
                                    <h3>
                                        <strong>Display Name:</strong>{" "}
                                        {this.props.users.currentUser &&
                                        this.props.users.currentUser
                                            .displayName &&
                                        this.props.users.currentUser
                                            .displayName !== ""
                                            ? this.props.users.currentUser
                                                  .displayName
                                            : ""}
                                    </h3>
                                </div>
                                <div className="col">
                                    <h3>
                                        <strong>Admin:</strong>{" "}
                                        {this.props.users.currentUser &&
                                        this.props.users.currentUser.isAdmin &&
                                        this.props.users.currentUser.isAdmin !==
                                            ""
                                            ? this.props.users.currentUser
                                                  .isAdmin
                                            : "False"}
                                    </h3>
                                    <h3>
                                        <strong>Created On:</strong>{" "}
                                        {this.props.users.currentUser &&
                                        this.props.users.currentUser
                                            .createdAt &&
                                        this.props.users.currentUser
                                            .createdAt !== ""
                                            ? new Date(
                                                  this.props.users.currentUser.createdAt
                                              ).toLocaleDateString()
                                            : ""}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    users: state.users
});

export default connect(mapStateToProps, {
    getUserById
})(ViewUser);
