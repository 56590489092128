import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { Navbar } from "dg-navbar";
import Swal from "sweetalert2";

// Styles
import "../../styles/components/navbar/navbar.scss";
import "../../styles/components/navbar/sideDrawer.scss";

//Assets
import arrowDown from "../../assets/icons/arrow-down.png";
import hanleyLogo from "../../assets/icons/logo-home.png";

import twitter from "../../assets/icons/twitter.svg";
import facebook from "../../assets/icons/facebook.svg";
import instagram from "../../assets/icons/instagram.svg";
import linkedin from "../../assets/icons/linkedin.svg";

import mobileNavbar from "../../assets/files/mobileNavbar";

//Actions
import {
    checkLoggedIn,
    getAccessToken,
    logout
} from "../../actions/login.action";

const navLogo = (
    <>
        <Link to="/" className="navbar-logo">
            <img src={hanleyLogo} alt="my logo" />
        </Link>
    </>
);

const navItems = [
    <>
        <NavLink exact={true} className="navbar-link" to="/">
            HOME
        </NavLink>
    </>,
    <>
        <NavLink exact={true} className="navbar-link" to="/classes">
            CLASSES
        </NavLink>
    </>,
    <>
        <NavLink exact={true} className="navbar-link" to="/members">
            VIDEO CLASSES
        </NavLink>
    </>,
    <>
        <NavLink exact={true} className="navbar-link" to="/about">
            ABOUT US
        </NavLink>
    </>,
    <>
        <NavLink exact={true} className="navbar-link" to="/contact">
            CONTACT US
        </NavLink>
    </>
];

const topContainer = (
    <div className="logo-container">
        <img src={hanleyLogo} alt="logo icon" />
    </div>
);

const bottomContainer = (
    <div className="socials-container">
        <a
            href="https://twitter.com/hanleyfit"
            rel="noopener noreferrer"
            target="_blank"
        >
            <img src={twitter} alt="twitter logo icon" />
        </a>
        <a
            href="https://www.facebook.com/HanleyFit/photos/?ref=page_internal"
            rel="noopener noreferrer"
            target="_blank"
        >
            <img src={facebook} alt="facebook logo icon" />
        </a>
        <a
            href="https://www.instagram.com/hanleyfit/"
            rel="noopener noreferrer"
            target="_blank"
        >
            <img src={instagram} alt="instagram logo icon" />
        </a>
        <a
            href="https://www.linkedin.com/in/colm-hanley-b4890739"
            rel="noopener noreferrer"
            target="_blank"
        >
            <img src={linkedin} alt="linkedin logo icon" />
        </a>
    </div>
);
class NavbarAlt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            isProfileMenuOpen: false
        };

        this.onLogout = this.onLogout.bind(this);
        this.isLoggedIn = this.isLoggedIn.bind(this);
    }

    onLogout(e) {
        e.preventDefault();

        this.props.logout();
    }

    isLoggedIn() {
        if (this.props.user.user.isActive) {
            this.setState(state => {
                return { loggedIn: true };
            });
        } else {
            let token = getAccessToken();

            if (token !== null) {
                this.props
                    .checkLoggedIn(token)
                    .then(res => {
                        this.setState(state => {
                            return { loggedIn: true };
                        });
                    })
                    .catch(res => {
                        Swal.fire(`Error`, res.message, "error");
                    });
            }

            this.setState(state => {
                return { loggedIn: false };
            });
        }
    }

    componentDidMount() {
        this.isLoggedIn();
    }

    render() {
        const login = (
            <div className="login-container">
                {!this.state.loggedIn ? (
                    <NavLink exact={true} to="/login">
                        <button className="btn-login">LOGIN</button>
                    </NavLink>
                ) : (
                    <div className="dropdown">
                        <div
                            className="dropbtn"
                            onClick={() =>
                                this.setState({
                                    isProfileMenuOpen: !this.state
                                        .isProfileMenuOpen
                                })
                            }
                        >
                            <h3>{this.props.user.user.displayName}</h3>
                            <img
                                src={arrowDown}
                                className={
                                    this.state.isProfileMenuOpen
                                        ? `upArrow`
                                        : ""
                                }
                                alt="arrow down"
                            />
                        </div>
                        <div
                            className={`dropdown-menu ${
                                this.state.isProfileMenuOpen ? `show` : ""
                            }`}
                        >
                            <div className="list-item">
                                <NavLink exact={true} to="/members">
                                    Video Classes
                                </NavLink>
                            </div>
                            <div className="list-item">
                                <button onClick={this.onLogout}>Logout</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
        return (
            <div style={this.props.style} className="navbar-container">
                <Navbar
                    logo={navLogo}
                    navbarRight={login}
                    burger={mobileNavbar.menuIcon}
                    mobileNavItem={login}
                    topContainer={topContainer}
                    closeIcon={mobileNavbar.closeIcon}
                    topContainerClass="top-container"
                    middleContainerClass="middle-container"
                    bottomContainerClass="bottom-container"
                    bottomContainer={bottomContainer}
                    navItems={navItems}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.loggedInUser
});

NavbarAlt.propTypes = {
    onLogout: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {
    checkLoggedIn,
    getAccessToken,
    logout
})(NavbarAlt);
