import React, { Component } from "react";
import { connect } from "react-redux";
import Slider from "rc-slider";

//Actions

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range); 

class DoubleSlider extends Component {

    render() {
        return (
            <div className="item">
                <div className="slider-container">
                    <div className="label-container">
                        <label className="min">
                            {this.props.videos.sliderValues[0]} mins
                        </label>
                        <label className="max">
                            {this.props.videos.sliderValues[1]} mins
                        </label>
                    </div>
                    <div className="range-container">
                        <Range
                            min={this.props.videos.initialSliderValues[0]}
                            max={this.props.videos.initialSliderValues[1]}
                            step={15}
                            dots={true}
                            allowCross={false}
                            onChange={(values) => this.props.onChange(
                                values,
                                this.props.collections[this.props.index].name
                            )}
                            value={this.props.videos.sliderValues}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    videos: state.videos
});

export default connect(mapStateToProps, {

})(DoubleSlider);
