import React, { Component } from "react";

// Styles
import "../styles/pages/contact.scss";

// Components
import Header from "../components/header/header.component";
import Footer from "../components/footer/footer.component";
import ContactForm from "../components/contact_form/contactForm.component";

class Contact extends Component {
    render() {
        return (
            <div className="contact-container">
                <Header text="CONTACT" />
                <ContactForm />
                <Footer />
            </div>
        );
    }
}
export default Contact;
