import {
    GET_VIDEOS_FAILED,
    FETCH_VIDEO,
    SET_CURRENT_VIDEO,
    GET_VIDEOS_SUCCESS,
    SEARCH_VIDEOS_SUCCESS,
    SEARCH_VIDEOS_FAILED,
    GENERATE_FILTERS_SUCCESS,
    SET_FILTERS,
    SET_QUERY,
    GENERATE_FILTERS_FAILED,
    SET_SLIDER_VALUES,
    SET_INITIAL_SLIDER_VALUES
} from "../actions/types";

const initialState = {
    message: "",
    ok: true,
    error: [],
    videos: [],
    query: {},
    filters: [],
    currentVideo: {},
    sliderValues: [],
    initialSliderValues: [15, 60]
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_INITIAL_SLIDER_VALUES:
            return {
                ...state,
                initialSliderValues: action.payload
            };
        case SET_SLIDER_VALUES:
            return {
                ...state,
                sliderValues: action.payload
            };
        case SET_QUERY:
            return {
                ...state,
                query: action.payload
            };
        case SET_FILTERS:
            return {
                ...state,
                filters: action.payload
            };
        case SET_CURRENT_VIDEO:
            return {
                ...state,
                currentVideo: action.payload
            };
        case GENERATE_FILTERS_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                videos: action.payload.filters,
                message: action.payload.message
            };
        case GENERATE_FILTERS_FAILED:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message
            };
        case SEARCH_VIDEOS_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                videos: action.payload.videos.videos,
                message: action.payload.message
            };
        case SEARCH_VIDEOS_FAILED:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message
            };
        case GET_VIDEOS_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                videos: action.payload.videos,
                message: action.payload.message
            };
        case GET_VIDEOS_FAILED:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message
            };
        case FETCH_VIDEO:
            return {
                ...state,
                currentVideo: action.payload.video,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message
            };
        default:
            return state;
    }
}
