import React from "react";
import "./styles/App.scss";
import { Provider } from "react-redux";
import { Switch, Route, Router } from "react-router-dom";
import ProtectedRoute from "./components/protectedRoute.component";
import ScrollToTop from "./scrollToTop";
import AdminRoute from "./components/adminRoute.component";
import { store } from "./store";
import history from "./history";

// Library Styles
import "redux-blogs/dist/index.css";
import "dg-forms/dist/index.css";
import "dg-testimonials/dist/index.css";
import "dg-navbar/dist/index.css";
import "dg-jumbotron/dist/index.css";
import "dg-blocks/dist/index.css";
import "dg-gallery/dist/index.css";
import "dg-pagination/dist/index.css";

//Pages
import Home from "./pages/home.component";
import About from "./pages/about.component";
import Access from "./pages/access.component";
import Classes from "./pages/classes.component";
import Members from "./pages/members.component";
import Class from "./pages/class.component";
import Contact from "./pages/contact.component";
import Login from "./components/login/login.component";
import PasswordReset from "./components/login/verifyPasswordReset.component";

// Admin - Contacts
import ContactList from "./components/admin/contact/list.component";
import ViewContact from "./components/admin/contact/view.component";

// Admin - Videos
import VideoList from "./components/admin/videos/list.component";
import ViewVideo from "./components/admin/videos/view.component";
import UpdateVideo from "./components/admin/videos/update.component";
import CreateVideo from "./components/admin/videos/create.component";
// Admin - Classes
import ClassList from "./components/admin/classes/list.component";
import ViewClass from "./components/admin/classes/view.component";
import UpdateClass from "./components/admin/classes/update.component";
import CreateClass from "./components/admin/classes/create.component";

// Admin - Users
import UserList from "./components/admin/users/list.component";
import ViewUser from "./components/admin/users/view.component";
import UpdateUser from "./components/admin/users/update.component";
import CreateUser from "./components/admin/users/create.component";

function App() {
    return (
        <Provider store={store}>
            <Router history={history}>
                <ScrollToTop />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/about" exact component={About} />
                    <Route path="/access" exact component={Access} />
                    <Route path="/classes" exact component={Classes} />
                    <Route path="/classes/:id" exact component={Class} />
                    <ProtectedRoute path="/members" exact component={Members} />
                    <Route path="/contact" exact component={Contact} />
                    <Route path="/login" exact component={Login} />
                    <Route
                        path="/verifypasswordreset"
                        exact
                        component={PasswordReset}
                    />
                    <AdminRoute
                        path="/admin/users"
                        exact
                        component={UserList}
                    />
                    <AdminRoute
                        path="/admin/user/update/:id"
                        exact
                        component={UpdateUser}
                    />
                    <AdminRoute
                        path="/admin/user/:id"
                        exact
                        component={ViewUser}
                    />
                    <AdminRoute
                        path="/admin/create-user/"
                        exact
                        component={CreateUser}
                    />
                    <AdminRoute
                        path="/admin/video/update/:id"
                        exact
                        component={UpdateVideo}
                    />
                    <AdminRoute
                        path="/admin/create-video/"
                        exact
                        component={CreateVideo}
                    />
                    <AdminRoute
                        path="/admin/videos"
                        exact
                        component={VideoList}
                    />
                    <AdminRoute
                        path="/admin/video/:id"
                        exact
                        component={ViewVideo}
                    />
                    <AdminRoute
                        path="/admin/class/update/:id"
                        exact
                        component={UpdateClass}
                    />
                    <AdminRoute
                        path="/admin/create-class/"
                        exact
                        component={CreateClass}
                    />
                    <AdminRoute
                        path="/admin/classes"
                        exact
                        component={ClassList}
                    />
                    <AdminRoute
                        path="/admin/class/:id"
                        exact
                        component={ViewClass}
                    />
                    <AdminRoute
                        path="/admin/contacts"
                        exact
                        component={ContactList}
                    />
                    <AdminRoute
                        path="/admin/contact/:id"
                        exact
                        component={ViewContact}
                    />
                </Switch>
            </Router>
        </Provider>
    );
}

export default App;
