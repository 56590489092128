import React, { Component } from "react";
import { connect } from "react-redux";
import { DgCustomForm } from "dg-forms";
import history from "../../../history";
import { Field } from "redux-form";
import { dgSelect, email, dgInput, required } from "dg-forms";
import Swal from "sweetalert2";

// Styles
import "../../../styles/components/admin/admin.scss";
import "../../../styles/components/admin/view.scss";

// Actions
import { createUser } from "../../../actions/users.action";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";

class CreateUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.handleSelectedFile = this.handleSelectedFile.bind(this);
    }
    handleSelectedFile(e) {
        e.preventDefault();

        this.setState({
            selectedFile: e.target.files
        });
    }

    onSubmit(data) {
        const form = new FormData();

        if (this.state.selectedFile && this.state.selectedFile.length > 0) {
            for (
                let index = 0;
                index < this.state.selectedFile.length;
                index++
            ) {
                form.append("files", this.state.selectedFile[index]);
            }
        }

        form.append("user_details", JSON.stringify(data));
        this.props
            .createUser(form)
            .then(res => {
                Swal.fire(`Success`, res.message, "success");
                history.push("/admin/users");
            })
            .catch(res => {
                Swal.fire(`Error`, res.message, "error");
            });
    }

    render() {
        let fields = [
            <>
                <div className="form-row">
                    <Field
                        name="username"
                        type="text"
                        component={dgInput}
                        label="Enter a username"
                        placeholder="USER NAME"
                        labelClass="required"
                        validate={[required]}
                    />
                    <Field
                        name="email"
                        type="text"
                        component={dgInput}
                        placeholder="EMAIL"
                        label="Enter an email"
                        labelClass="required"
                        validate={[required, email]}
                    />
                </div>
                <div className="form-row">
                    <Field
                        name="displayName"
                        type="text"
                        component={dgInput}
                        placeholder="DISPLAY NAME"
                        labelClass="required"
                        label="Enter a display name"
                        validate={[required]}
                    />
                    <Field
                        labelClass="required"
                        label="Administrator"
                        name="isAdmin"
                        component={dgSelect}
                    >
                        <option default value="false">
                            False
                        </option>
                        <option value="true">True</option>
                    </Field>
                </div>
                <div className="form-row">
                    <Field
                        name="password"
                        type="password"
                        component={dgInput}
                        label="Password"
                        labelClass="required"
                        validate={[required]}
                    />
                </div>
            </>
        ];
        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="form-container">
                        <DgCustomForm
                            headerContainerClass="header"
                            formHeader={<h1>Create User</h1>}
                            innerContainerClass=""
                            buttonContainerClass="button-container"
                            fields={fields}
                            submitText="Create User"
                            submitClass="btn-watch"
                            onSubmit={this.onSubmit}
                        />
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    users: state.users
});

export default connect(mapStateToProps, {
    createUser
})(CreateUser);
