import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import history from "../../../history";
import { Pagination } from "dg-pagination";

// Styles
import "../../../styles/components/admin/list.scss";
import "../../../styles/components/admin/admin.scss";

// Actions
import { deleteClass, getClasses } from "../../../actions/class.action";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";
import ListOptions from "./listOptions";

class ClassList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            page_size: 10,
            total_pages: 0,
            total_items: 0
        };

        this.paginate = this.paginate.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onEdit = this.onEdit.bind(this);
    }

    componentDidMount() {
        this.paginate(this.state.page, this.state.page_size);
    }

    paginate(page, page_size) {
        this.props
            .getClasses(page, page_size)
            .then(res => {
                this.setState({
                    page: res.page,
                    page_size: res.page_size,
                    total_pages: res.total_pages,
                    total_items: res.total_items
                });
            })
            .catch(res => {
                Swal.fire(`Error`, res.message, "error");
            });
    }

    onDelete(event, value) {
        event.preventDefault();
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(result => {
            if (result.isConfirmed) {
                this.props.deleteClass(value._id);
                Swal.fire("Deleted!", "Class has been deleted.", "success");
                this.componentDidMount();
            }
        });
    }

    onEdit(event, value) {
        event.preventDefault();
        history.push(`/admin/class/update/${value._id}`);
    }
    render() {
        let rows = [];

        if (
            this.props.classes &&
            this.props.classes.classes &&
            this.props.classes.classes.length > 0
        ) {
            rows = this.props.classes.classes.map((value, index) => (
                <tr>
                    <td
                        onClick={() =>
                            history.push(`/admin/class/${value._id}`)
                        }
                    >
                        {value.class_details.title}
                    </td>
                    <td className="mobile-hide"
                        onClick={() =>
                            history.push(`/admin/class/${value._id}`)
                        }
                    >
                        {value.class_details.function}
                    </td>
                    <td className="mobile-hide"
                        onClick={() =>
                            history.push(`/admin/class/${value._id}`)
                        }
                    >
                        {value.class_details.price}
                    </td>
                    <td className="mobile-hide"
                        onClick={() =>
                            history.push(`/admin/class/${value._id}`)
                        }
                    >
                        {new Date(value.createdAt).toLocaleDateString()}{" "}
                        {new Date(value.createdAt).toLocaleTimeString()}
                    </td>
                    <td>
                        <ListOptions
                            onEdit={e => this.onEdit(e, value)}
                            onDelete={e => this.onDelete(e, value)}
                        />
                    </td>
                </tr>
            ));
        } else {
            rows = [
                <tr>
                    <td colSpan="5">No Classes available ...</td>
                </tr>
            ];
        }

        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="List-Container">
                        <div className="item head">
                            <h1>All Classes</h1>
                        </div>
                        <div className="list">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Class Title</th>
                                        <th className="mobile-hide">Class Function</th>
                                        <th className="mobile-hide">Class Price</th>
                                        <th className="mobile-hide">Created On</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>{rows}</tbody>
                            </table>
                        <div className="create-container">
                            <button
                                className="btn-watch"
                                onClick={() =>
                                    history.push(`/admin/create-class`)
                                }
                            >
                                Create Class
                            </button>
                        </div>
                            <Pagination
                                type="Standard"
                                paginate={this.paginate}
                                page_size={this.state.page_size}
                                page={this.state.page}
                                total_pages={this.state.total_pages}
                                total_items={this.state.total_items}
                            />
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    classes: state.classes,
    user: state.loggedInUser
});

export default connect(mapStateToProps, {
    getClasses,
    deleteClass
})(ClassList);
