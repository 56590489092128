import React, { Component } from "react";
import { connect } from "react-redux";
import { DgCustomForm } from "dg-forms";
import history from "../../../history";
import { Field } from "redux-form";
import { dgTextArea, dgSelect, dgUpload, dgInput, required } from "dg-forms";
import Swal from "sweetalert2";

//Assets
import step1 from "../../../assets/webAssets/step-1.png";
import step2 from "../../../assets/webAssets/step-2.png";
import step3 from "../../../assets/webAssets/step-3.png";

// Styles
import "../../../styles/components/admin/admin.scss";
import "../../../styles/components/admin/view.scss";

// Actions
import { createVideo } from "../../../actions/video.action";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";
import Item from "../../accordian/item.component";

class CreateVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.handleSelectedFile = this.handleSelectedFile.bind(this);
    }
    handleSelectedFile(e) {
        e.preventDefault();

        this.setState({
            selectedFile: e.target.files
        });
    }

    onSubmit(data) {
        const form = new FormData();

        if (this.state.selectedFile && this.state.selectedFile.length > 0) {
            for (
                let index = 0;
                index < this.state.selectedFile.length;
                index++
            ) {
                form.append("files", this.state.selectedFile[index]);
            }
        }

        form.append("video_details", JSON.stringify(data));
        this.props
            .createVideo(form)
            .then(res => {
                Swal.fire(`Success`, res.message, "success");
                history.push("/admin/videos");
            })
            .catch(res => {
                Swal.fire(`Error`, res.message, "error");
            });
    }
    render() {
        let fields = [
            <>
                <div className="form-row">
                    <Field
                        name="video_details.title"
                        type="text"
                        component={dgInput}
                        label="Enter a title"
                        placeholder="TITLE"
                        labelClass="required"
                        validate={[required]}
                    />
                    <Field
                        labelClass="required"
                        label="Enter a category"
                        name="video_details.category"
                        component={dgSelect}
                        validate={[required]}
                    >
                        <option value="" selected>
                            Please Select a Category
                        </option>
                        <option value="Introduction To Pilates" selected>
                            Introduction to Pilates
                        </option>
                        <option value="Pilates Level 1" selected>
                            Pilates Level 1 (Beginner)
                        </option>
                        <option value="Pilates Level 2" selected>
                            Pilates Level 2 (Improver)
                        </option>
                        <option value="Pilates Level 3" selected>
                            Pilates Level 3 (Intermediate)
                        </option>
                        <option value="Pilates Level 4" selected>
                            Pilates Level 4 (Advanced)
                        </option>
                        <option value="Balance Workouts" selected>
                            Balance Workouts
                        </option>
                        <option value="Cardio Workouts" selected>
                            Cardio Workouts
                        </option>
                        <option value="Chair Workouts" selected>
                            Chair Workouts
                        </option>
                        <option value="Stretching/Mobility" selected>
                            Stretching/Mobility
                        </option>
                        <option value="Pilates with Equipment" selected>
                           Pilates with Equipment 
                        </option>
                        <option value="Kids (and adults) Fun" selected>
                            Kid's (and adults) Fun
                        </option>
                    </Field>
                </div>
                <div className="form-row">
                    <Field
                        labelClass="required"
                        label="Enter a duration"
                        name="video_details.duration"
                        component={dgSelect}
                        validate={[required]}
                    >
                        <option value="" selected>
                            Please Select a Duration
                        </option>
                        <option value="15" selected>
                            15 Minutes or Less
                        </option>
                        <option value="30" selected>
                            30 Minutes or Less
                        </option>
                        <option value="45" selected>
                            45 Minutes or Less
                        </option>
                        <option value="60" selected>
                            60 Minutes or Less
                        </option>
                    </Field>
                    <Field
                        name="video_details.url"
                        type="text"
                        component={dgInput}
                        placeholder="YOUTUBE URL"
                        labelClass="required"
                        label="Enter the YouTube url"
                        validate={[required]}
                    />
                </div>
                <div className="form-row">
                    <Field
                        name="video_details.summary"
                        type="text"
                        component={dgTextArea}
                        placeholder="SUMMARY"
                        labelClass="required"
                        label="Enter a summary"
                        validate={[required]}
                    />
                </div>
                <Field
                    multiple={false}
                    label="Class Image"
                    labelClass="required"
                    name="files"
                    onChange={this.handleSelectedFile}
                    accept={".png, .jpg, .jpeg"}
                    component={dgUpload}
                />
            </>
        ];
        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="form-container">
                        <Item
                            isHidden={true}
                            title={
                                <div className="card-header">
                                    <h1>
                                        Video Upload Help
                                    </h1>{" "}
                                </div>
                            }
                            component={
                                <div
                                    className="card-row"
                                    style={{ flex: "1 1 100%" }}
                                >
                                    <div className="card-bottom header">
                                        <div className="step">
                                            <div className="text">
                                                <h1>Step 1</h1>
                                                <p>
                                                    On the video you want to
                                                    upload, click the 'Share'
                                                    button underneath it.
                                                </p>
                                            </div>
                                            <div className="step-image">
                                                <img src={step1} alt="step 1" />
                                            </div>
                                        </div>
                                        <div className="step">
                                            <div className="text">
                                                <h1>Step 2</h1>
                                                <p>
                                                    In the share menu, click the
                                                    'copy' button to copy the
                                                    video url.
                                                </p>
                                            </div>
                                            <div className="step-image">
                                                <img src={step2} alt="step 1" />
                                            </div>
                                            <div className="step">
                                                <div className="text">
                                                    <h1>Step 3</h1>
                                                    <p>
                                                        In the create video
                                                        section, paste the video
                                                        url
                                                    </p>
                                                </div>
                                                <div className="step-image">
                                                    <img
                                                        src={step3}
                                                        alt="step 1"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        />
                        <DgCustomForm
                            innerContainerClass=""
                            headerContainerClass="header"
                            formHeader={<h1>Create Video</h1>}
                            buttonContainerClass="button-container"
                            fields={fields}
                            submitText="Create Video Class"
                            submitClass="btn-watch"
                            onSubmit={this.onSubmit}
                        />
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    videos: state.videos
});

export default connect(mapStateToProps, {
    createVideo
})(CreateVideo);
