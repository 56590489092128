import React from "react";
export const testimonials = [
    <>
        <p className="description">
            I have worked with Colm for the last few years, attending his
            Pilates classes and currently participating online. Colm is an
            excellent teacher and explains breathing, muscle groups and
            technique as we go along.
        </p>
        <p className="description">
            I highly recommend. You will not be disappointed.
        </p>
        <p>
            <strong>Theresa</strong>
        </p>
    </>,
    <>
        <p className="description">
            Colm’s classes and online tutorials are very well structured and
            easy to follow. Not too easy to do!! But you feel safe doing them at
            your own capability. I have been doing them with Colm for over 2
            years and have gained better flexibility and strength as a result.
        </p>
        <p>
            <strong>Jim</strong>
        </p>
    </>,
    <>
        <p className="description">
            We know we should stretch after exercising at home, but often don’t
            have the discipline. Since attending Colm’s classes twice weekly
            over the past few years, my flexibility, core strength and balance
            have improved immeasurably. These classes are all I need for a
            strong core for hiking and cycling. No 2 classes are ever the same.
            Colm varies the exercises, focusing on different areas of the body
            to work on. He is patient and good humoured and I really miss the
            classes with this lockdown.
        </p>
        <p>
            <strong>Catherine</strong>
        </p>
    </>,
    <>
        <p className="description">
            I started Pilates with Colm about five years ago, it's the best
            thing I have ever done for myself, the classes have strengthened my
            whole body, I have flexibility that I didn't think possible at my
            age.
        </p>
        <p className="description">
            Colm is an excellent instructor and focuses on ensuring that
            breathing and general technique are correct at all times, he always
            takes into account the various ailments that anyone has from time to
            time and adjusts exercise accordingly.
        </p>
        <p>
            <strong>Frances</strong>
        </p>
    </>,
    <>
        <p className="description">
            When Covid descended upon us and our Pilates had to be put on hold,
            I really missed the class. When Colm began to create online classes
            via YouTube, I never expected the online classes to be such a good
            substitute for the actual class.
        </p>
        <p className="description">
            For me, it is super! It's like a one to one class, with all the
            emphasis on detailed instruction that ensures I am practicing
            Pilates correctly. The big bonus is that I can do my Pilates
            whenever suits me, and no matter where I am.
        </p>
        <p className="description">
            I have introduced friends and family to Colm's Improver and
            Intermediate Pilates classes, and the feedback is very positive. My
            son did a class with me while visiting, and was very impressed.
            Doing an improver level class in deference to me, he was quite
            surprised and challenged!! He does Pilates twice a week……Thanks to
            Colm, Pilates has become an essential part of my life.
        </p>
        <p>
            <strong>Jennifer</strong>
        </p>
    </>,
    <>
        <p className="description">
            I have attended Colm’s Pilates class over the last few years, having
            been recommended by a Health Professional. Colm is professional and
            knowledgeable and is well prepared for each class. He ensures that
            individual ailments are catered for. His affable and encouraging
            nature ensures that participants have an enjoyable and beneficial
            experience.
        </p>
        <p>
            <strong>Margaret</strong>
        </p>
    </>,
    <>
        <p className="description">
            Pilates is the most effective form of exercise I have ever
            undertaken, significantly improving my overall strength and
            flexibility. Even when I am tired I persevere with my Pilates
            Classes – I see it now as part of my DNA to a healthier body and
            mind.
        </p>
        <p className="description">
            Colm is an excellent, dedicated and proactive teacher challenging us
            by mixing up the class content every week. He explains each movement
            and its impact in detail and is always on hand to offer advice if
            you find a move difficult. He teaches you the importance of the
            correct technique from day one.
        </p>
        <p className="description">
            Pilates is the only class that I have stayed the distance with and
            it is due to the quality of the teaching from Colm and the brilliant
            results I have attained as a result. I cannot recommend him enough.
        </p>
        <p>
            <strong>Majella</strong>
        </p>
    </>,
    <>
        <p className="description">
            Having done numerous fitness classes over the years, Colm’s Pilates
            Classes are up there with the best.
        </p>
        <p className="description">
            I have been attending his classes for close to six years now, so it
            has become an essential part of my fitness routine and a great help
            to me in preventing injuries. Lots of variety, often tough but
            always enjoyable!
        </p>
        <p>
            <strong>Noreen</strong>
        </p>
    </>,
    <>
        <p className="description">
            Colm provides excellent Pilates instruction, both in class and
            online. His attention to detail and variety of programmes, all
            delivered in a convivial atmosphere, makes his Pilates class
            experience most enjoyable.
        </p>
        <p>
            <strong>Stephen</strong>
        </p>
    </>,
    <>
        <p className="description">
            I enjoy Colm's classes immensely. He is a great teacher - he
            explains and demonstrates each movement very clearly. The pace of
            the class is perfect, the 45 minutes fly by and I love the fact that
            it can be challenging at times!! Two years ago the physio suggested
            Pilates for hip and shoulder issues and recommended Colm - I haven’t
            looked back!
        </p>
        <p>
            <strong>Deirdre</strong>
        </p>
    </>,
    <>
        <p className="description">
            I have been going to Colm’s classes for the last number of years
            after a back injury. This has really improved my quality of life. I
            find the classes great and really find a difference if I miss a
            week.
        </p>
        <p className="description">
            Since current restrictions I have been following his videos weekly
            on YouTube and find them excellent, really clear instructions and
            always helpful reminders how to exercise carefully avoiding injury.
            He has really improved my flexibility and posture and my awareness
            of the importance of flexibility in helping avoid injury in the long
            term.
        </p>
        <p className="description">Keep it up Colm and thank you.</p>
        <p>
            <strong>Liam</strong>
        </p>
    </>,
    <>
        <p className="description">
            I have attended Colm’s Pilates class over the last few years, having
            been recommended by a Health Professional. Colm is professional and
            knowledgeable and is well prepared for each class. He ensures that
            individual ailments are catered for. His affable and encouraging
            nature ensures that participants have an enjoyable and beneficial
            experience.
        </p>
        <p>
            <strong>Carol</strong>
        </p>
    </>
];
export default testimonials;
