import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { MultiColumn, Card, TwoColumn } from "dg-blocks";
import Swal from "sweetalert2";

// Styles
import "../styles/pages/classes.scss";

//Assets
import legitLogo from "../assets/webAssets/legitfit-logo.png";
import tablet from "../assets/webAssets/tablet.png";

// Components
import Header from "../components/header/header.component";
import Footer from "../components/footer/footer.component";

//Actions
import { getClasses } from "../actions/class.action";

class Classes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            page_size: 6,
            total_pages: 0,
            total_items: 0
        };

        this.paginate = this.paginate.bind(this);
    }

    componentDidMount() {
        this.paginate(this.state.page, this.state.page_size);
    }

    paginate(page, page_size) {
        this.props
            .getClasses(page, page_size)
            .then(res => {
                this.setState({
                    page: res.page,
                    page_size: res.page_size,
                    total_pages: res.total_pages,
                    total_items: res.total_items
                });
            })
            .catch(res => {
                Swal.fire(`Error`, res.message, "error");
            });
    }

    render() {
        let items = [];

        if (
            this.props.classes &&
            this.props.classes.classes &&
            this.props.classes.classes.length > 0
        ) {
            items = this.props.classes.classes.map((value, index) => (
                <Card
                    cardImage={
                        <NavLink
                            className="image-link"
                            exact={true}
                            to={`/classes/${value._id}`}
                        >
                            {value.files.slice(0).map((file, index) => (
                                <img alt="attached class" src={file.url} />
                            ))}
                        </NavLink>
                    }
                    cardBody={
                        <>
                            <h3>{value.class_details.function}</h3>
                            <h2>{value.class_details.title}</h2>
                            <p>{value.class_details.summary}</p>
                            <button className="btn-watch">
                                <NavLink
                                    exact={true}
                                    to={`/classes/${value._id}`}
                                >
                                    LEARN MORE
                                </NavLink>
                            </button>
                        </>
                    }
                />
            ));
        }
        return (
            <div className="classes-container">
                <Header text="CLASSES" />
                <MultiColumn
                    posts={items}
                    errorClass={"error-container"}
                    errorMessage={
                        <>
                            <h3>No Classes Available</h3>
                            <p>
                                We're sorry, there doesn't seem to be any
                                classes available at the moment!
                            </p>
                        </>
                    }
                />
                <TwoColumn
                    containerClass="twoCol-container"
                    rightColClass="container-column right"
                    rightColumn={
                        <>
                            <img
                                src={tablet}
                                className="mobile-image"
                                alt="legitfit tablet"
                            />
                        </>
                    }
                    leftColClass="container-column left"
                    leftColumn={
                        <div className="features-container">
                            <img
                                src={legitLogo}
                                className="legit-image"
                                alt="legitfit mobile logo"
                            />
                            <h1>Legitfit</h1>
                            <p>
                                Fitness management solution that helps fitness
                                entrepreneurs streamline their day and manage
                                customer schedules and payments.
                            </p>
                            <p>Some of the amazing features:</p>
                            <ul>
                                <li>Private Dashboard</li>
                                <li>Shared Timetable with Clients</li>
                                <li>Take Bookings and Payments Online</li>
                                <li>Track Your Business and Clients</li>
                                <li>Sell Packages and Memberships</li>
                            </ul>
                            <a
                                href="https://legitfit.com/authenticate/signin"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button className="btn-watch">
                                    LEARN MORE
                                </button>
                            </a>
                        </div>
                    }
                />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    classes: state.classes,
    currentClass: state.classes.currentClass
});

export default connect(mapStateToProps, {
    getClasses
})(Classes);
