import { SET_CURRENT_USER, DELETE_USER, CREATE_USER, GET_USERS } from "./types";
import { getAccessToken } from "./login.action";

export const createUser = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let user = getState().form.Custom_form.values;
        let token = getAccessToken();

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/create`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
                access_token: token
            },
            body: JSON.stringify(user)
        })
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: CREATE_USER,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const getUsers = (page, page_size) => (dispatch, getState) => {
    let token = getAccessToken();
    return new Promise((resolve, reject) => {
        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/?page=${page}&page_size=${page_size}`,
            {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    access_token: token
                }
            }
        )
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: GET_USERS,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const getUserById = userId => (dispatch, getState) => {
    let token = getAccessToken();

    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/get/${userId}`, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                access_token: token
            }
        })
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: CREATE_USER,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const deleteUser = (userEmail) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let token = getAccessToken();
        let body = {email: userEmail};
        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/delete/${userEmail}`,
            {
                method: "DELETE",
                headers: {
                    "content-type": "application/json",
                    access_token: token
                },
                body: JSON.stringify(body)
            }
        )
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: DELETE_USER,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const updateUser = (userId, data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let token = getAccessToken();

        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/update/${userId}`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json",
                    access_token: token
                },
                body: JSON.stringify(data)
            }
        )
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: CREATE_USER,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const setCurrentUser = data => dispatch => {
    dispatch({
        type: SET_CURRENT_USER,
        payload: data
    });
};
