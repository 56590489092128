import React from "react";

//Styles
import "../../styles/components/header/header.scss";

//Components
import Navbar from "../navbar/navbarAlt.component";

const Header = props => (
    <div className="header-alt-container">
        <Navbar />
        <div className="text-container">
            <h1>{props.text}</h1>
        </div>
    </div>
);
export default Header;
