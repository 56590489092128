import React, { Component } from "react";
import { connect } from "react-redux";
import { DgCustomForm } from "dg-forms";
import history from "../../../history";
import { Field } from "redux-form";
import { dgSelect, dgTextArea, dgInput, required } from "dg-forms";
import Swal from "sweetalert2";

// Styles
// import '../../styles/components/login/login.scss';
import "../../../styles/components/admin/admin.scss";

// Actions
import {
    getVideoById,
    updateVideo,
    setCurrentVideo
} from "../../../actions/video.action";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";

let fields = [
    <>
        <div className="form-row">
            <Field
                name="video_details.title"
                type="text"
                component={dgInput}
                label="Enter a title"
                placeholder="TITLE"
                labelClass="required"
                validate={[required]}
            />
            <Field
                labelClass="required"
                label="Enter a category"
                name="video_details.category"
                component={dgSelect}
                validate={[required]}
            >
                <option value="" selected>
                    Please Select a Category
                </option>
                <option value="Introduction To Pilates" selected>
                    Introduction to Pilates
                </option>
                <option value="Pilates Level 1" selected>
                    Pilates Level 1 (Beginner)
                </option>
                <option value="Pilates Level 2" selected>
                    Pilates Level 2 (Improver)
                </option>
                <option value="Pilates Level 3" selected>
                    Pilates Level 3 (Intermediate)
                </option>
                <option value="Pilates Level 4" selected>
                    Pilates Level 4 (Advanced)
                </option>
                <option value="Balance Workouts" selected>
                    Balance Workouts
                </option>
                <option value="Cardio Workouts" selected>
                    Cardio Workouts
                </option>
                <option value="Chair Workouts" selected>
                    Chair Workouts
                </option>
                <option value="Stretching/Mobility" selected>
                    Stretching/Mobility
                </option>
            </Field>
        </div>
        <div className="form-row">
            <Field
                labelClass="required"
                label="Enter a duration"
                name="video_details.duration"
                component={dgSelect}
                validate={[required]}
            >
                <option value="" selected>
                    Please Select a Duration
                </option>
                <option value="15" selected>
                    15 Minutes or Less
                </option>
                <option value="30" selected>
                    30 Minutes or Less
                </option>
                <option value="45" selected>
                    45 Minutes or Less
                </option>
                <option value="60" selected>
                    60 Minutes or Less
                </option>
            </Field>
            <Field
                name="video_details.url"
                type="text"
                component={dgInput}
                placeholder="YOUTUBE URL"
                labelClass="required"
                label="Enter the YouTube url"
                validate={[required]}
            />
        </div>
        <div className="form-row">
            <Field
                name="video_details.summary"
                type="text"
                component={dgTextArea}
                placeholder="SUMMARY"
                labelClass="required"
                label="Enter a summary"
                validate={[required]}
            />
        </div>
    </>
];

class EditVideo extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        let urlParams = window.location.pathname;
        let params = urlParams.split("/");

        if (params.length >= 4) {
            this.props
                .getVideoById(params[4])
                .then(res => {
                    this.props.setCurrentVideo(res.video);
                })
                .catch(res => {
                    Swal.fire("Error", res.message, "error");
                });
        }
    }

    onSubmit(data) {
        console.log(data);

        this.props
            .updateVideo(this.props.videos.currentVideo._id, data)
            .then(res => {
                Swal.fire(`Video Updated`, res.message, "success");
                history.push("/admin/videos");
            })
            .catch(res => {
                Swal.fire(`Error`, res.message, "error");
            });
    }

    render() {
        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="form-container">
                        <DgCustomForm
                            innerContainerClass=""
                            headerContainerClass="header"
                            formHeader={<h1>Edit Video</h1>}
                            buttonContainerClass="button-container"
                            fields={fields}
                            initialValues={this.props.videos.currentVideo}
                            enableReinitialize={true}
                            submitText="Update Video Class"
                            submitClass="btn-watch"
                            onSubmit={this.onSubmit}
                        />
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    videos: state.videos
});

export default connect(mapStateToProps, {
    getVideoById,
    updateVideo,
    setCurrentVideo
})(EditVideo);
