import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import loginReducer from './login.reducer';
import contactReducer from './contact.reducer';
import userReducer from './users.reducer';
import classReducer from './class.reducer';
import videoReducer from './video.reducer';
import visibleReducer from './visible.reducer';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    loggedInUser: loginReducer,
    form: formReducer,
    contacts: contactReducer,
    users: userReducer,
    classes: classReducer,
    videos: videoReducer,
    visible: visibleReducer,
});

export default createRootReducer;
