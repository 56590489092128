import {
    GET_VIDEOS_FAILED,
    GET_VIDEOS_SUCCESS,
    SEARCH_VIDEOS_SUCCESS,
    SEARCH_VIDEOS_FAILED,
    GENERATE_FILTERS_FAILED,
    GENERATE_FILTERS_SUCCESS,
    FETCH_VIDEO,
    CREATE_VIDEO,
    SET_FILTERS,
    DELETE_VIDEO,
    SET_QUERY,
    SET_CURRENT_VIDEO,
    SET_SLIDER_VALUES,
    SET_INITIAL_SLIDER_VALUES
} from "./types";
import { getAccessToken } from "./login.action";

export const setInitialSliderValues = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_INITIAL_SLIDER_VALUES,
            payload: data
        });
        resolve(data);
    });
};

export const setSliderValues = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_SLIDER_VALUES,
            payload: data
        });
        resolve(data);
    });
};

export const setFilters = data => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_FILTERS,
            payload: data
        });
        resolve(data);
    });
};

export const setQuery = data => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_QUERY,
            payload: data
        });
        resolve(data);
    });
};

export const generateFilters = collections => dispatch => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/products/filters/`, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(collections)
        })
            .then(res => res.json())
            .then(post => {
                if (!post.ok) {
                    dispatch({
                        type: GENERATE_FILTERS_FAILED,
                        payload: post
                    });

                    reject(post);
                } else {
                    dispatch({
                        type: GENERATE_FILTERS_SUCCESS,
                        payload: post
                    });

                    resolve(post);
                }
            });
    });
};

export const createVideoSearch = (page, page_size, filters) => (
    dispatch,
    getState
) => {
    return new Promise((resolve, reject) => {
        let query = {};
        let params = "?";
        
        for (const key in filters) {
            if (Object.hasOwnProperty.call(filters, key)) {
                const element = filters[key];
                query[key] = JSON.stringify(element);
            }
        }
    
        for (const key in query) {
            if (Object.hasOwnProperty.call(query, key)) {
                const element = query[key];
                
                params = `${params}${params === `?` ? `${key}="${element}"` : `&${key}="${element}"` }`;
            }
        } 

        console.log(params);
        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/videos/search/${params}&page=${page}&page_size=${page_size}`,
            {
                method: "GET",
                headers: {
                    "content-type": "application/json"
                }
            }
        )
            .then(res => res.json())
            .then(post => {
                if (!post.ok) {
                    dispatch({
                        type: SEARCH_VIDEOS_FAILED,
                        payload: post
                    });

                    reject(post);
                } else {
                    dispatch({
                        type: SEARCH_VIDEOS_SUCCESS,
                        payload: post
                    });

                    resolve(post);
                }
            });
    });
};

export const createVideo = data => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/videos/create`, {
            method: "POST",
            body: data
        })
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: CREATE_VIDEO,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const getVideos = (page, page_size) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/videos/?page=${page}&page_size=${page_size}`,
            {
                method: "GET",
                headers: {
                    "content-type": "application/json"
                }
            }
        )
            .then(res => res.json())
            .then(post => {
                if (!post.ok) {
                    dispatch({
                        type: GET_VIDEOS_FAILED,
                        payload: post
                    });

                    reject(post);
                } else {
                    dispatch({
                        type: GET_VIDEOS_SUCCESS,
                        payload: post
                    });

                    resolve(post);
                }
            });
    });
};

export const getVideoById = id => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/videos/get/${id}`, {
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(res => res.json())
            .then(post => {
                if (!post.ok) {
                    dispatch({
                        type: FETCH_VIDEO,
                        payload: post
                    });

                    reject(post);
                } else {
                    dispatch({
                        type: FETCH_VIDEO,
                        payload: post
                    });

                    resolve(post);
                }
            });
    });
};

export const deleteVideo = videoId => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let token = getAccessToken();
        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/videos/delete/${videoId}`,
            {
                method: "DELETE",
                headers: {
                    "content-type": "application/json",
                    access_token: token
                }
            }
        )
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: DELETE_VIDEO,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const updateVideo = (videoId, data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let token = getAccessToken();

        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/videos/update/${videoId}`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json",
                    access_token: token
                },
                body: JSON.stringify(data)
            }
        )
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: CREATE_VIDEO,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const setCurrentVideo = data => dispatch => {
    dispatch({
        type: SET_CURRENT_VIDEO,
        payload: data
    });
};
