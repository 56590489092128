import React from "react";
import { Field, reduxForm } from "redux-form";
import { dgInput, required, email } from "dg-forms";

const VerifyForm = props => {
    const { handleSubmit, pristine, submitting } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="header">
                <h1>Reset Password</h1>
            </div>
            <Field
                name="email"
                type="email"
                component={dgInput}
                label="Email"
                labelClass="required"
                validate={[required, email]}
            />
            <Field
                name="token"
                type="text"
                component={dgInput}
                label="Token"
                labelClass="required"
                validate={[required]}
            />
            <Field
                name="newPassword"
                type="password"
                component={dgInput}
                label="New Password"
                labelClass="required"
                validate={[required]}
            />
            <div className="button-container">
                <button
                    disabled={pristine || submitting}
                    type="submit"
                    className="btn-main"
                >
                    Reset Password
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "VerifyForm" // a unique identifier for this form
})(VerifyForm);
