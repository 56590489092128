import React, { Component } from "react";
import { connect } from "react-redux";
import { DgCustomForm } from "dg-forms";
import { Field } from "redux-form";
import { dgTextArea, dgInput, required, email, phoneNumber } from "dg-forms";
import Swal from "sweetalert2";

//Styles
import "../../styles/components/contact_form/contactForm.scss";

// Actions
import { createContact } from "../../actions/contact.action";

let fields = [
    <>
        <div className="form-row">
            <Field
                name="your_details.name"
                type="text"
                component={dgInput}
                placeholder="YOUR NAME"
                labelClass="required"
                validate={[required]}
            />
            <Field
                name="your_details.phone"
                type="text"
                component={dgInput}
                placeholder="YOUR PHONE"
                labelClass="required"
                validate={[required, phoneNumber]}
            />
        </div>
        <div className="form-row">
            <Field
                name="your_details.email"
                type="email"
                component={dgInput}
                placeholder="YOUR EMAIL"
                labelClass="required"
                validate={[required, email]}
            />

            <Field
                name="your_details.reason"
                type="text"
                component={dgInput}
                placeholder="REASON FOR CONTACT"
                labelClass="required"
                validate={[required]}
            />
        </div>
        <div className="form-row">
            <Field
                name="your_details.message"
                type="text"
                component={dgTextArea}
                placeholder="HOW CAN I HELP YOU"
                labelClass="required"
                validate={[required]}
            />
        </div>
    </>
];
class FormPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1
        };
        this.nextPage = this.nextPage.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    nextPage() {
        this.setState({ page: this.state.page + 1 });
    }

    onSubmit(form) {
        this.props
            .createContact()
            .then(res => {
                this.nextPage();
            })
            .catch(res => {
                Swal.fire(`Error`, res.message, "error");
            });
    }

    render() {
        return (
            <div className="form-container">
                <div className="contact-header">
                    <h1>Get in Touch</h1>
                    <p>
                        Do you have a question about any workout, or just want
                        to say hello? Go ahead and send me an email or use the
                        contact form below!
                    </p>
                </div>
                <div className="image-container">
                    {this.state.page === 1 && (
                        <DgCustomForm
                            formClass="contact-form"
                            headerContainerClass=""
                            formHeader={
                                <h1>Looking forward to meeting you!</h1>
                            }
                            innerContainerClass=""
                            buttonContainerClass="button-container"
                            fields={fields}
                            submitText="Send Message"
                            submitClass="btn-watch"
                            onSubmit={this.onSubmit}
                            currentPage={this.state.page}
                        />
                    )}
                    {this.state.page === 2 && (
                        <form class="contact-form">
                            <div className="header-container">
                                <h1>Message Submitted!</h1>
                                <p>
                                    Thank you for your message, we'll be in
                                    touch shortly!
                                </p>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    form: state.form.contact
});

export default connect(mapStateToProps, {
    createContact
})(FormPage);
