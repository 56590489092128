import React from "react";
import { NavLink } from "react-router-dom";

//Styles
import "../../styles/components/footer/footer.scss";

//Assets
import logo from "../../assets/icons/logo-digigrow.png";
import twitter from "../../assets/icons/twitter-dark.svg";
import facebook from "../../assets/icons/facebook-dark.svg";
import instagram from "../../assets/icons/instagram-dark.svg";
import linkedin from "../../assets/icons/linkedin-dark.svg";

//Components
import { Footer } from "dg-blocks";

const Foot = props => (
    <Footer
        footerTop={
            <>
                <ul>
                    <li>
                        <NavLink exact={true} to="/">
                            HOME
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact={true} to="/classes">
                            CLASSES
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact={true} to="/members">
                            VIDEO CLASSES
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact={true} to="/about">
                            ABOUT
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact={true} to="/contact">
                            CONTACT US
                        </NavLink>
                    </li>
                </ul>
                <hr />
                <div className="socials">
                    <a
                        href="https://twitter.com/hanleyfit"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <img src={twitter} alt="twitter logo icon" />
                    </a>
                    <a
                        href="https://www.facebook.com/HanleyFit/photos/?ref=page_internal"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <img src={facebook} alt="facebook logo icon" />
                    </a>
                    <a
                        href="https://www.instagram.com/hanleyfit/"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <img src={instagram} alt="instagram logo icon" />
                    </a>
                    <a
                        href="https://www.linkedin.com/in/colm-hanley-b4890739"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <img src={linkedin} alt="linkedin logo icon" />
                    </a>
                </div>
            </>
        }
        footerBottom={
            <>
                <div className="disclaimer-container">
                    <p>&copy; 2020 Hanley Fit</p>{" "}
                </div>
                <div className="digigrow-container">
                    <a
                        href="https://digigrow.ie/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <p>Designed and Developed by </p>
                        <img src={logo} alt="digigrow footer logo" />
                    </a>
                </div>
            </>
        }
    />
);
export default Foot;
