import React, { Component } from "react";
import { TwoColumn } from "dg-blocks";

// Styles
import "../styles/pages/access.scss";

//Assets
import videoImage from "../assets/webAssets/notebook-what-are.png";
import man from "../assets/webAssets/handsome-concentrated-man-doing-yoga-matt.png";
import woman from "../assets/webAssets/bg-wo-2.jpg";
import shape from "../assets/webAssets/shapes-how-to.png";
import shape2 from "../assets/webAssets/shapes-how-to-become.png";
import legitLogo from "../assets/webAssets/legitfit-logo.png";
import mobile from "../assets/webAssets/mobile-legitfit.png";

// Components
import Header from "../components/header/header.component";
import Footer from "../components/footer/footer.component";

class Access extends Component {
    render() {
        return (
            <div className="access-container">
                <Header text="HOW TO ACCESS VIDEO CLASSES" />
                <TwoColumn
                    rightColClass="container-column right"
                    rightColumn={
                        <>
                            <img
                                src={videoImage}
                                className="video-image"
                                alt="what are video classes"
                            />
                        </>
                    }
                    leftColClass="container-column left"
                    leftColumn={
                        <>
                            <h1>WHAT ARE THE VIDEO CLASSES</h1>
                            <p>
                                The video classes are an ever-growing catalogue
                                which brings the experience of going to the gym
                                into your home.
                            </p>
                            <p>
                                There are a range of difficulty levels and video
                                lengths so you can find the right class for you.
                            </p>
                            <p>
                                The video classes are uploaded to a private
                                YouTube channel and the only place you can
                                access them is right here on HanleyFit.com.
                            </p>
                        </>
                    }
                />
                <TwoColumn
                    containerClass="twoCol-container-alt"
                    leftColClass="container-column-alt left"
                    leftColumn={
                        <>
                            <img
                                src={shape}
                                className="shape-image"
                                alt="background shape"
                            />
                            <img
                                src={man}
                                className="man-image"
                                alt="man doing yoga"
                            />
                        </>
                    }
                    rightColClass="container-column-alt right"
                    rightColumn={
                        <>
                            <h1>HOW TO ACCESS THE VIDEO CLASSES</h1>
                            <p>
                                To access video classes, you must be a HanleyFit
                                member.
                            </p>
                            <p>
                                If you are already a HanleyFit Member simply
                                click the “Login” button in the top left hand
                                corner of your screen and enter your
                                credentials.
                            </p>
                            <p>Once you are logged in you can either:</p>
                            <ol>
                                <li>
                                    Click on your profile to view a drop-down
                                    menu which contains the “Video Classes” page
                                    or...
                                </li>
                                <li>
                                    Once signed in, clicking on the link to this
                                    page will direct you to the “Video Classes
                                    Members” page.
                                </li>
                            </ol>
                        </>
                    }
                />
                <TwoColumn
                    containerClass="twoCol-container"
                    rightColClass="container-column right"
                    rightColumn={
                        <>
                            <img
                                src={mobile}
                                className="mobile-image"
                                alt="legitfit mobile"
                            />
                        </>
                    }
                    leftColClass="container-column left"
                    leftColumn={
                        <>
                            <img
                                src={legitLogo}
                                className="legit-image"
                                alt="legitfit mobile logo"
                            />
                            <h1>HOW TO BECOME A MEMBER OF LEGITFIT</h1>
                            <p>
                                Fitness management solution that helps fitness
                                entrepeneurs streamline their day and manage
                                customer scheduels and payments.
                            </p>
                            <p>Some of the amazing features:</p>
                            <ul>
                                <li>Private Dashboard</li>
                                <li>Shared Timetable with Clients</li>
                                <li>Take Bookings and Payments Online</li>
                                <li>Track Your Business and Clients</li>
                                <li>Sell Packages and Memberships</li>
                            </ul>
                            <a
                                href="https://legitfit.com/authenticate/signin"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button className="btn-watch">
                                    LEARN MORE
                                </button>
                            </a>
                        </>
                    }
                />
                <TwoColumn
                    containerClass="twoCol-container-alt"
                    leftColClass="container-column-alt left"
                    leftColumn={
                        <>
                            <img
                                src={shape2}
                                className="shape2-image"
                                alt="background shape"
                            />
                            <img
                                src={woman}
                                className="man-image"
                                alt="woman doing yoga"
                            />
                        </>
                    }
                    rightColClass="container-column-alt right"
                    rightColumn={
                        <>
                            <h1>HOW TO BECOME A MEMBER OF HANLEY FIT</h1>
                            <p>
                                Currently, we accept new signups by enquiry
                                only. If you are interested in signing up as a
                                HanleyFit member please contact us via our
                                contact form and we will follow up as soon as
                                possible.
                            </p>
                            <p>
                                There is a monthly cost of €18 for membership.
                            </p>
                            <p>Membership Benefits:</p>
                            <ul>
                                <li>
                                    Access to an ever-growing video catalogue,
                                    including 4 levels of Mat Pilates,
                                </li>
                                <li>
                                    Support choosing the right level and type of
                                    workout for you,
                                </li>
                                <li>
                                    Balance Workouts, Prop Workouts, Stretching
                                    Routines,
                                </li>
                                <li>
                                    New workouts regularly, from my home to
                                    yours,
                                </li>
                                <li>
                                    Fun ideas to get the kids (and you!) moving{" "}
                                </li>
                            </ul>
                            <p></p>
                        </>
                    }
                />
                <Footer />
            </div>
        );
    }
}
export default Access;
