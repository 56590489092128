import React from "react";
export const testimonialsMobile = [
    <>
        <p className="description">
            I have worked with Colm for the last few years, attending his
            Pilates classes and currently participating online. Colm is an
            excellent teacher and explains breathing, muscle groups and
            technique as we go along.
        </p>
        <p className="description">
            I highly recommend. You will not be disappointed.
        </p>
        <p>
            <strong>Theresa</strong>
        </p>
    </>,
    <>
        <p className="description">
            Colm’s classes and online tutorials are very well structured and
            easy to follow. Not too easy to do!! But you feel safe doing them at
            your own capability. I have been doing them with Colm for over 2
            years and have gained better flexibility and strength as a result.
        </p>
        <p>
            <strong>Jim</strong>
        </p>
    </>,
    <>
        <p className="description">
            We know we should stretch after exercising at home, but often don’t
            have the discipline. Since attending Colm’s classes twice weekly
            over the past few years, my flexibility, core strength and balance
            have improved immeasurably. These classes are all I need for a
            strong core for hiking and cycling. No 2 classes are ever the same.
            Colm varies the exercises, focusing on different areas of the body
            to work on. He is patient and good humoured and I really miss the
            classes with this lockdown.
        </p>
        <p>
            <strong>Catherine</strong>
        </p>
    </>,
    <>
        <p className="description">
            I have attended Colm’s Pilates class over the last few years, having
            been recommended by a Health Professional. Colm is professional and
            knowledgeable and is well prepared for each class. He ensures that
            individual ailments are catered for. His affable and encouraging
            nature ensures that participants have an enjoyable and beneficial
            experience.
        </p>
        <p>
            <strong>Margaret</strong>
        </p>
    </>,
    <>
        <p className="description">
            Colm provides excellent Pilates instruction, both in class and
            online. His attention to detail and variety of programmes, all
            delivered in a convivial atmosphere, makes his Pilates class
            experience most enjoyable.
        </p>
        <p>
            <strong>Stephen</strong>
        </p>
    </>,
    <>
        <p className="description">
            I enjoy Colm's classes immensely. He is a great teacher - he
            explains and demonstrates each movement very clearly. The pace of
            the class is perfect, the 45 minutes fly by and I love the fact that
            it can be challenging at times!! Two years ago the physio suggested
            Pilates for hip and shoulder issues and recommended Colm - I haven’t
            looked back!
        </p>
        <p>
            <strong>Deirdre</strong>
        </p>
    </>,
    <>
        <p className="description">
            I have attended Colm’s Pilates class over the last few years, having
            been recommended by a Health Professional. Colm is professional and
            knowledgeable and is well prepared for each class. He ensures that
            individual ailments are catered for. His affable and encouraging
            nature ensures that participants have an enjoyable and beneficial
            experience.
        </p>
        <p>
            <strong>Carol</strong>
        </p>
    </>
];
export default testimonialsMobile;
