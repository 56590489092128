import React, { Component } from "react";
import { connect } from "react-redux";
import { DgCustomForm } from "dg-forms";
import history from "../../../history";
import { Field } from "redux-form";
import { dgSelect, email, dgInput, required } from "dg-forms";
import Swal from "sweetalert2";

// Styles
// import '../../styles/components/login/login.scss';
import "../../../styles/components/admin/admin.scss";

// Actions
import {
    getUserById,
    updateUser,
    setCurrentUser
} from "../../../actions/users.action";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";

let fields = [
    <>
        <div className="form-row">
            <Field
                name="username"
                type="text"
                component={dgInput}
                label="Enter a username"
                placeholder="USER NAME"
                labelClass="required"
                validate={[required]}
            />
            <Field
                name="email"
                type="text"
                component={dgInput}
                placeholder="EMAIL"
                label="Enter an email"
                labelClass="required"
                validate={[required, email]}
            />
        </div>
        <div className="form-row">
            <Field
                name="displayName"
                type="text"
                component={dgInput}
                placeholder="DISPLAY NAME"
                labelClass="required"
                label="Enter a display name"
                validate={[required]}
            />
            <Field
                labelClass="required"
                label="Administrator"
                name="isAdmin"
                component={dgSelect}
            >
                <option default value="false">
                    False
                </option>
                <option value="true">True</option>
            </Field>
        </div>
        <div className="form-row">
            <Field
                name="password"
                type="password"
                component={dgInput}
                label="Password"
                labelClass="required"
                validate={[required]}
            />
        </div>
    </>
];

class EditUser extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        let urlParams = window.location.pathname;
        let params = urlParams.split("/");

        if (params.length >= 4) {
            this.props
                .getUserById(params[4])
                .then(res => {
                    this.props.setCurrentUser(res.user);
                })
                .catch(res => {
                    Swal.fire("Error", res.message, "error");
                });
        }
    }

    onSubmit(data) {
        console.log(data);

        this.props
            .updateUser(this.props.users.currentUser._id, data)
            .then(res => {
                Swal.fire(`User Updated`, res.message, "success");
                history.push("/admin/users");
            })
            .catch(res => {
                Swal.fire(`Error`, res.message, "error");
            });
    }

    render() {
        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="form-container">
                        <DgCustomForm
                            innerContainerClass=""
                            headerContainerClass="header"
                            formHeader={<h1>Edit User</h1>}
                            buttonContainerClass="button-container"
                            fields={fields}
                            submitText="Update User"
                            initialValues={this.props.users.currentUser}
                            enableReinitialize={true}
                            submitClass="btn-watch"
                            onSubmit={this.onSubmit}
                        />
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    users: state.users
});

export default connect(mapStateToProps, {
    getUserById,
    updateUser,
    setCurrentUser
})(EditUser);
